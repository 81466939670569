import { KeyboardAvoidingView, StyleSheet, Text, TextInput, TouchableOpacity, View, Image, Modal, TouchableWithoutFeedback, Linking, FlatList, ScrollView, ImageBackground, Alert } from 'react-native'
import React from 'react'
import { COLORS, FONTS, images, SIZES, ANIMATIONS, dummyData, icons, theme, constants } from '../constants'
import { useNavigation } from '@react-navigation/native'
import { SkypeIndicator } from 'react-native-indicators'
/* import { FormInput } from '../components'
import { utils } from '../utils'  */
//import { NotificationCard } from '../components'
import { apiController, utils } from '../utils'
import HyperLink from 'react-native-hyperlink';
//import Barcode from 'react-native-barcode-builder'
//import Barcode from '@kichiyaki/react-native-barcode-generator';
import Barcode from 'react-native-barcode-expo';
import QRCode from 'react-native-qrcode-svg';
import base64 from 'react-native-base64'
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'
import { auth, firestore } from '../firebase'
import { collection, addDoc, setDoc, doc, onSnapshot } from 'firebase/firestore'
import AsyncStorage from '@react-native-async-storage/async-storage'
import BouncyCheckbox from 'react-native-bouncy-checkbox'
//import BundleActionModal from './BundleActionModal'
//import { Header } from '../components'
const Section = ({ title, onPress, children, actionTitle }) => {
    return (
        <View>
            {/* Header */}
            <View
                style={{
                    flexDirection: 'row',
                    marginHorizontal: SIZES.padding,
                    marginBottom: 12,
                    marginTop: 10,
                    justifyContent: 'space-between'
                }}>
                <Text
                    style={{
                        fontSize: 18,
                        fontWeight: 'normal',
                        color: COLORS.white,
                        textAlign: 'center',
                    }}
                    allowFontScaling={false}>
                    {title}
                </Text>

                <TouchableOpacity
                    onPress={onPress}
                >
                    <Text style={{
                        fontSize: 18,
                        fontWeight: 'normal',
                        color: COLORS.white,
                        textAlign: 'center',
                    }}>
                        {actionTitle}
                    </Text>
                </TouchableOpacity>
            </View>

            {/* Content */}
            {children}
        </View>
    );
};


const Home = ({ route }) => {

    const [confirm, setConfirm] = React.useState(null);
    const [timer, setTimer] = React.useState(30);
    const [otp, setOtp] = React.useState('');
    const [OtpVerified, setOtpVerified] = React.useState(false);
    const [otpError, setOtpError] = React.useState(constants.NO_ERROR_MESSAGE);
    //for resend otp
    const [vc, setVc] = React.useState('');
    const [didUser, setDidUser] = React.useState(null);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [coupons, setCoupons] = React.useState([]);
    const [listCoupons, setListCoupons] = React.useState([]);
    const [couponsOthers, setCouponsOthers] = React.useState([]);
    const [showLoader, setShowLoader] = React.useState(false);
    const [displayNoCouponView, setDisplayNoCouponView] = React.useState(false);
    const [item, setItem] = React.useState(null);
    const [showCouponDetailModal, setShowCouponDetailModal] = React.useState(false);
    const [validFor, setValidFor] = React.useState(310);
    const [fetchCode, setFetchCode] = React.useState('.');
    const [termsModal, setTermsModal] = React.useState(false);
    const [descriptionView, setDescriptionView] = React.useState(false)
    const [isConfirmed, setIsComfirmed] = React.useState(false);
    const [defaultCodeType, setDefaultCodeType] = React.useState(null);
    const [showBarcode, setShowBarcode] = React.useState(false);
    const [redeemed, setRedeemd] = React.useState(false);
    const [deposited, setDeposited] = React.useState(false);
    const [showCouponExistsAlert, setShowCouponExistsAlert] = React.useState(false);

    const [indicatorVisible, setIndicatorVisible] = React.useState(false);
    const [confirmation, setConfirmation] = React.useState(false);
    const [txnId, setTxnId] = React.useState('');
    // Error Modal View
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorTitle, setErrorTitle] = React.useState('');

    //Coupon Delete Modal
    const [deleteItem, setDeleteItem] = React.useState(null);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [deleteMessage, setDeleteMessage] = React.useState('');
    const [deleteTitle, setDeleteTitle] = React.useState('');

    //Coupon Select Modal
    const [actionTitle, setActionTitle] = React.useState('Select');
    const [selectedCoupons, setSelectedCoupons] = React.useState([]);
    const [selectedCouponsCount, setSelectedCouponsCount] = React.useState(0);
    const [selectedCouponsValue, setSelectedCouponsValue] = React.useState('0.00');
    const [showBundleAction, setShowBundleAction] = React.useState(false);
    const [bundleId, setBundleId] = React.useState('.');
    const [expandedBundleId, setExpandedBundleId] = React.useState('.');


    const navigation = useNavigation()
    const getDidUser = async () => {
        const vc = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PROVIDER_VC);
        const phone_number = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PHONE_NUMBER);
        let did = null;
        try {
            did = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
        } catch (error) {
            //console.log("DID_USER_EXCEPTION", error);
        }

        setDidUser(did);
        setVc(vc);
        setPhoneNumber(phone_number);
        
        /* Check the url and deposit coupon */

        //console.log("HOME_HREF: ", window.location.href); // Outputs: 'https://web.gitsy.app/{base_gs1}'
        //console.log("HOME_PROTOCOL: ", window.location.protocol);// Outputs: 'https:'
        //console.log("HOME_HOSTNAME: ", window.location.hostname);    // Outputs: 'timmousk.com'

        // Outputs: '/blog/react-get-current-url/'
        //console.log("HOME_PATHNAME: ", window.location.pathname)
        if (phone_number != null && window.location.pathname != '/') {
            //console.log("HOME_PATHNAME_TRUE: ", window.location.pathname)
            let pathname = window.location.pathname.replace("/", "");
            let base_gs1 = "";
            let bundle_of;
            if (pathname.includes("_")) {
                try {
                    pathname = pathname.split('_');
                    base_gs1 = pathname[0];
                    bundle_of = pathname[1];
                } catch (error) {
                    console.log(error);
                }
                //console.log("HOME_PATHNAME_BASE_GS1: ", base_gs1);
                //console.log("HOME_PATHNAME_BUNDLE_OF: ", bundle_of);
            }
            else{
                base_gs1 = pathname;
                //console.log("HOME_PATHNAME_BASE_GS1: ", base_gs1);
            }
            
            setListCoupons([]);
            apiController.deposit(phone_number, base_gs1, bundle_of, setDeposited, setShowCouponExistsAlert, setErrorTitle, setErrorMessage);
        }
    }
    React.useEffect(() => {
        try {
            let { phoneNumber } = route.params;
            setPhoneNumber(phoneNumber);
            //let { vc } = route.params;
            //setVc(vc);

            /* let didUser = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
            setDidUser(didUser); */
        } catch (error) {

        }
        getDidUser();

        let interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    return prevTimer;
                }
            });
        }, 1000);

        // return a function for react to clean up
        //cleanup the interval
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        //console.log('OTP_VERIFIED:', OtpVerified);
        //console.log('HOME_VC1:', vc);
        if (vc != '') {
            apiController.getAllValidCoupons(vc, setCoupons, setCouponsOthers, setShowLoader, setDisplayNoCouponView);
        }
    }, [vc]);

    React.useEffect(() => {
        //console.log('OTP_VERIFIED:', OtpVerified);
        console.log('HOME_VC1:', deposited);
        /* if (deposited) {
            apiController.getAllValidCoupons(vc, setCoupons, setCouponsOthers, setShowLoader, setDisplayNoCouponView);
        } */
        if (deposited) {
            setDeposited(false);
            window.open("https://web.gitsy.app/", "_self");
        }
        //
    }, [deposited]);

    React.useEffect(() => {
        //console.log('OTP_VERIFIED:', OtpVerified);
        //console.log('HOME_VC1:', vc);
        if (showCouponExistsAlert) {
            setShowErrorModal(true);
            //setErrorTitle('COUPON EXISTS')
            //setErrorMessage('This coupon is already in your Gitsy™ wallet')
        }
    }, [showCouponExistsAlert]);

    React.useEffect(() => {
        //console.log('OTP_VERIFIED:', OtpVerified);
        //console.log('HOME_COUPONS:', coupons.length);
        /* uncomment below code to display only one coupon */
        /* if (coupons.length>0) {
            let arr = [];
            arr.push(coupons[0]);
            setListCoupons(arr);
        }
        else setListCoupons([]); */

        /* Comment below code to display only one coupon */
        setListCoupons(coupons);

    }, [coupons]);

    React.useEffect(() => {
        //console.log('OTP_VERIFIED:', OtpVerified);
        //console.log('SELECTED_COUPON:', item);
        if (item != null) {
            //setShowCouponDetailModal(true);
            const unsubscribe = onSnapshot(
                doc(firestore, "open_coupons/" + didUser + "/coupons", item.serialized_gs1),
                (snapshot) => {
                    // ...
                    try {
                        //console.log('COUPON DATA SNAPSHOT_COUPON_MODAL: ', documentSnapshot.data());
                        let coupon = snapshot.data()
                        if (coupon) {
                            //let openCouponSnapshot = availableCoupons.coupons.find(openCoupon => openCoupon.serialized_gs1 === item.serialized_gs1)
                            //console.log("SNAPSHOT_UPDATE", coupon.redeemed);
                            setRedeemd(coupon.redeemed);
                            if (coupon.redeemed) {
                                //setCoupons([]);
                                apiController.getAllValidCoupons(vc, setCoupons, setCouponsOthers, setShowLoader, setDisplayNoCouponView);
                                setActionTitle('Select');
                                setSelectedCoupons([]);
                            }
                        }
                    } catch (error) {
                        //console.log(error);
                    }
                },
                (error) => {
                    // ...
                    //console.error("SNAPSHOT_UPDATE_ERROR", error);
                });

            // Stop listening for updates when no longer required
            return () => unsubscribe();
        }
    }, [item]);

    let pushCouponToDB = async (item) => {
        const docref = await setDoc(doc(firestore, "open_coupons/" + didUser + "/coupons", item.serialized_gs1), { ...item, redeemed: false });
        setShowCouponDetailModal(true);
    }
    let pushBundleCouponToDB = async (item) => {
        const docref = await setDoc(doc(firestore, "open_coupons/" + didUser + "/coupons", item.serialized_gs1), { ...item, redeemed: false });
    }

    let signOut = async () => {
        //alert(number)
        //const auth = getAuth();
        auth.signOut();

        auth.onAuthStateChanged(user => {
            if (user) {
                //setAuthenticated(true);
                //alert("USER STILL SIGNED IN");
            } else {
                /*
                  const popAction = StackActions.pop(1);
                  navigation.dispatch(popAction);
                  */
                utils.storeStringData(
                    constants.ASYNC_STORAGE_DATA.SKIPPED_SIGN_IN,
                    'false',
                );
                utils.removeStringData(constants.ASYNC_STORAGE_DATA.PROVIDER_VC)
                //navigation.navigate('SignIn');
                navigation.reset({
                    index: 0,
                    routes: [
                        {
                            name: 'Signin',

                        },
                    ],
                });
                //window.open("https://web.gitsy.app/", "_self");
                window.open(window.location.protocol + "//" + window.location.hostname, "_self");
                //console.log(window.location.protocol+"//"+ window.location.hostname);
            }
        });


    }
    function onChangeValue(item, value) {
        //console.log("CHECKBOX_VALUE:", item.title + " : " + value);
        //setSelectedTab(constants.screens.myCoupons);
        let arr = selectedCoupons;
        if (value === true) {

            arr.push(item);
            //setSelectedCoupons(selectedCoupons + 1);
            setSelectedCoupons(arr);
        }
        else if (value === false) {
            //setSelectedCoupons(selectedCoupons - 1);
            if (arr.length > 0) {

                arr.splice(arr.indexOf(item), 1);
                setSelectedCoupons(arr);
            }
        }
        console.log("CHECKBOX_VALUE:", value, selectedCoupons.length);
        setSelectedCouponsCount(selectedCoupons.length);
        setSelectedCouponsValue(utils.getTotalCouponsValue(selectedCoupons))
        //setSelectedTab(arr.length>0?  utils.randomId(1)+"My Coupons": constants.screens.my_wallet);
        //setSelectedActionType(arr.length > 0 ? utils.randomId(1) : constants.action_types.action_none);
    }
    function renderHeader() {
        return (
            <View
                style={{
                    height: 80,
                    flexDirection: 'row',
                    paddingTop: 10,
                    paddingHorizontal:SIZES.padding,
                    width: '100%',
                    maxWidth: SIZES.height,
                    marginTop:0,
                    alignItems: 'center',
                    alignContent:'center',
                    backgroundColor: COLORS.white,
                    borderBottomRightRadius: 20,
                    borderBottomLeftRadius: 20,
                }}>
                <TouchableOpacity
                    style={{
                        width: 40,
                        height: 40,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    //disabled={disabled}
                    onPress={() => Linking.openURL("https://static.zdassets.com/admin/contributor/admin_center_framework_web_widget/e46160a5e0add69a85f8c0697e4e1a76c18dde0c/05595a2b304b40283e4ae32e4d7f3795.html#key=7d2e44f6-3f41-4b42-ba4b-5798c7e39069&instructions=Welcome+to+Gitsy+support.+Tap+the+chat+button+to+initiate+help.&dir=ltr&locale=en-us&hasCustomLauncher=false&customLauncherOpen=Open+Web+Widget&title=Spectivlabs")}>
                    <Image
                        source={icons.chatbot}
                        style={{
                            width: 40,
                            height: 40,
                            alignSelf: 'center',
                            tintColor: COLORS.primaryTextColor,
                        }}
                    />
                </TouchableOpacity>
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Image
                        source={images.logo_02}
                        resizeMode="contain"
                        style={{
                            height: 40,
                            width: 120,
                            tintColor: COLORS.primary
                        }}
                    />
                </View>
                <TouchableOpacity
                    style={{
                        width: 40,
                        height: 40,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    //disabled={disabled}
                    onPress={() => signOut()}
                    >
                    <Image
                        source={icons.logout}
                        style={{
                            width: 30,
                            height: 30,
                            alignSelf: 'center',
                            tintColor: COLORS.primaryTextColor,
                        }}
                    />
                </TouchableOpacity>
            </View>

        );
    }
    return (
        <KeyboardAvoidingView style={styles.container}>
            <ImageBackground 
            source={images.frame} 
            resizeMode="cover" 
            style={{flex: 1, width:'100%', height:'100%',position: 'absolute',
                alignItems: 'center',}}>

    
            {/* {listCoupons.length > 0 &&    
            <View
                style={{
                    flex:1,
                    width:'100%',
                    flexDirection: 'row',
                    justifyContent:'space-between',
                    alignItems:'center',
                    marginHorizontal: SIZES.padding,
                    marginBottom: 12,
                    marginTop: 10,
                    paddingHorizontal:10
                }}>
                <Image
                    source={images.logo_02}
                    resizeMode="contain"
                    style={{
                        height: 100,
                        width: 120,
                    }}
                />
                <Text
                    style={{
                        fontSize: 18,
                        fontWeight: 'normal',
                        color: COLORS.white,
                        textAlign: 'center',
                    }}
                    allowFontScaling={false}>
                    Logout
                </Text>

            </View>
            } */}
            {/* Header */}
            {renderHeader()}
            {/* <Image
                source={images.logo_02}
                resizeMode="contain"
                style={{
                    height: 100,
                    width: 120,
                }}
            /> */}
            {/* {listCoupons.length>=2 &&
            <Text
                style={{
                    color: COLORS.white,
                    textDecorationLine: 'underline',
                    fontWeight: 'normal',
                    fontStyle:'italic',
                    fontSize: 14,
                    textAlign: 'center',
                    marginTop:-10
                }}
                allowFontScaling={false}
                onPress={() => Linking.openURL("https://download.gitsy.app")}>
                Download the app for more.
            </Text>
            }    */}
            {/* {listCoupons.length == 0 &&
            <Image
                source={images.logo_02}
                resizeMode="contain"
                style={{
                    height: 100,
                    width: 120,
                }}
            />   
            }     */}
            {redeemed && listCoupons.length == 0 && (
                <Text
                    style={{
                        fontWeight: "normal",
                        fontSize: 28,
                        color: COLORS.white,
                        textAlign: "center",
                        marginBottom: 10,
                        marginTop: 10,
                    }}
                    allowFontScaling={false}
                >
                    Thank you.
                </Text>
            )}

            {listCoupons.length > 0 &&
                <View
                    style={{
                        width: SIZES.width * 0.9,
                        maxWidth: SIZES.height,
                    }}>
                    <Section
                        title={listCoupons.length+" Coupons"}
                        actionTitle={actionTitle}
                        onPress={() => {
                            if (actionTitle == "Select") {
                                setActionTitle('Cancel');
                            }
                            else if (actionTitle == "Cancel") {
                                setActionTitle('Select');
                                setSelectedCoupons([]);
                            }
                        }}
                    />
                </View>
            }

            {listCoupons.length > 0 && (
                <FlatList
                    style={{ paddingBottom: 40 }}
                    data={listCoupons}
                    persistentScrollbar={true}
                    //keyExtractor={item => `${item.id}`}
                    showsVerticalScrollIndicator={true}
                    /* ListHeaderComponent={
                      <View>
                        <Section
                          title={"Your coupons " + listCoupons.length}
                          actionTitle={actionTitle}
                          onPress={() => {
                            if (actionTitle == "Select") {
                                setActionTitle('Cancel');
                            }
                            else if (actionTitle == "Cancel") {
                                setActionTitle('Select');
                            }
                          }}
                        />
                      </View>
                    } */
                    renderItem={({ item, index }) => {
                        return (
                            //<NotificationCard notificationItem={item}/>
                            <TouchableOpacity
                                style={{
                                    flexDirection: "row",
                                    borderRadius: SIZES.radius,
                                    backgroundColor: COLORS.white,
                                    height: 100,
                                    alignItems: "center",
                                    marginHorizontal: SIZES.radius,
                                    marginBottom: SIZES.radius,
                                    width: SIZES.width * 0.9,
                                    maxWidth: SIZES.height,
                                }}
                                //disabled={true}
                                onPress={() => {
                                    //console.log("CLICKED");
                                    pushCouponToDB(item);
                                    setItem(item);
                                    setRedeemd(false);
                                }}
                            //onLongPress={onLongPress}
                            >
                                {/* Image */}
                                <Image
                                    source={{ uri: item.mobile_image_url }}
                                    resizeMode="contain"
                                    style={{
                                        height: 84,
                                        width: 84,
                                        marginRight: SIZES.radius,
                                        marginLeft: SIZES.radius,
                                    }}
                                />

                                {/* Info */}
                                <View
                                    style={{
                                        flex: 1,
                                        height: "100%",
                                        flexDirection: "column",
                                        paddingVertical: SIZES.radius,
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <View style={{}}>
                                        {/* Name */}
                                        <Text
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: 20,
                                                lineHeight: 22,
                                                color: COLORS.gray4,
                                            }}
                                            allowFontScaling={false}
                                            numberOfLines={1}
                                        >
                                            {item.title}
                                            {/* <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 20,
                                                    lineHeight: 22,
                                                    color: COLORS.primary,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                $ {utils.trimDecimals(item.dollar_amount, 2)}
                                            </Text> */}
                                        </Text>
                                        {/* Description */}
                                        <Text
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: 16,
                                                //lineHeight: 22,
                                                color: COLORS.gray4,
                                            }}
                                            numberOfLines={2}
                                            allowFontScaling={false}
                                            ellipsizeMode="tail"
                                        >
                                            {item.description}
                                        </Text>
                                        {/* <Text
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                            color: COLORS.gray4,
                                        }}
                                        allowFontScaling={false}
                                        numberOfLines={1}>
                                        EXP: {utils.getMmDd(item.valid_till)}
                                    </Text> */}
                                    </View>
                                    <Text
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: 14,
                                            color: COLORS.gray4,
                                        }}
                                        allowFontScaling={false}
                                        numberOfLines={1}
                                    >
                                        EXP: {utils.getMmDd(item.valid_till)}
                                    </Text>
                                </View>
                                {actionTitle == 'Select' &&
                                    <TouchableOpacity
                                        style={{
                                            width: 30,
                                            height: 30,
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                            marginRight: 10,
                                            marginLeft: 10,
                                        }}
                                        onPress={() => {
                                            //console.log("CLICKED");
                                            setDeleteItem(item);
                                            setShowDeleteModal(true);
                                        }}
                                    >
                                        <Image
                                            source={icons.bndl_delete}
                                            style={{
                                                alignSelf: "center",
                                                tintColor: COLORS.gray3,
                                                height: 25,
                                                width: 25,
                                            }}
                                        />
                                    </TouchableOpacity>
                                }
                                {actionTitle == 'Cancel' &&
                                    <View
                                        style={
                                            {

                                                marginRight: 10,
                                                marginLeft: 10
                                            }
                                        }
                                    >
                                        <Image
                                            source={icons.circle_plus}
                                            resizeMode="cover"
                                            style={{
                                                height: 30,
                                                width: 30,
                                                opacity: 1,
                                                backgroundColor: COLORS.appBackground,
                                                transform: [{ rotate: "90deg" }],
                                                borderRadius: 15,
                                            }}
                                        />

                                        <BouncyCheckbox
                                            style={{
                                                backgroundColor: COLORS.transparent,
                                                //marginRight: -20,
                                                position: "absolute",
                                            }}
                                            size={30}
                                            fillColor={COLORS.primary}
                                            unfillColor={COLORS.transparent}
                                            iconImageStyle={{ tintColor: COLORS.white }}
                                            onPress={(value) => {
                                                //console.log('ITEM:', item);
                                                onChangeValue(item, value);
                                                //console.log('NUMBER:', value, number);
                                                //setShowBundleAction(true);
                                                //setShowCouponSelectedView(false);
                                                //setSelectedTab(constants.screens.my_wallet);
                                            }}
                                            isChecked={false}
                                        />
                                    </View>
                                }
                            </TouchableOpacity>
                        );
                    }}
                    
                />
            )}
            {/* {listCoupons.length>=2 &&
            <TouchableOpacity
                style={{
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    backgroundColor: COLORS.blue,
                    marginBottom:10,
                    borderWidth: 4,
                    height: 40,
                    width: 150,
                    alignItems: 'center',
                    marginTop: 10,
                    borderRadius: 40,
                    borderWidth: SIZES.borderWidth3,
                    borderColor: COLORS.white,
                    opacity: 1,
                }}
                
                onPress={() => Linking.openURL("https://download.gitsy.app")}>

                <Text style={{ color: COLORS.white, fontWeight: 'normal', fontSize:12 }} allowFontScaling={false}>
                    Download Gitsy™ App
                </Text>

            </TouchableOpacity>
            } */}
            {/* Bundle summary view with action */}
            {selectedCoupons.length > 0 &&
                <View
                    style={{
                        width: SIZES.width ,
                        maxWidth: SIZES.height,
                        backgroundColor: COLORS.blue,
                        opacity: 0.9,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        position: 'absolute',
                        bottom: 0,
                    }}>
                    <View
                        style={{
                            marginTop: 10,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingLeft: SIZES.padding,
                            paddingRight: SIZES.padding,
                        }}>
                        {/* Name */}
                        <Text
                            style={{
                                fontWeight: 'bold',
                                fontSize: 26,
                                color: COLORS.white,
                                textAlign: 'center',
                            }}
                            allowFontScaling={false}
                            numberOfLines={2}>
                            {selectedCouponsCount} {'\n'}
                            <Text
                                style={{
                                    color: COLORS.white,
                                    fontWeight: 'normal',
                                    fontSize: 12,
                                }}
                                allowFontScaling={false}>
                                Coupons Selected
                            </Text>
                        </Text>
                        <TouchableOpacity
                            style={{
                                alignItems: "center",
                                alignSelf: "center",
                                justifyContent: "center",
                                backgroundColor: COLORS.primary,
                                height: 50,
                                //width: 80,
                                borderRadius: 25,
                                borderWidth: SIZES.borderWidth2,
                                borderColor: COLORS.white,
                                shadowColor: COLORS.gray5,
                                backgroundColor: COLORS.primary,
                                color: COLORS.white,
                                fontSize: 18,
                                paddingLeft:10,
                                paddingRight:10,
                            }}
                            onPress={() => {
                                setShowBundleAction(true);
                                pushBundleCouponToDB(selectedCoupons[0]);
                                setItem(selectedCoupons[0]);
                                setRedeemd(false);
                                apiController.getBundleIdforBundle(selectedCoupons, setBundleId, setFetchCode, setExpandedBundleId, setValidFor);
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    color: COLORS.white,
                                    textAlign: 'center'
                                }}
                                allowFontScaling={false}
                            >
                                Use Now
                            </Text>
                        </TouchableOpacity>
                        <Text
                            style={{
                                fontWeight: 'bold',
                                fontSize: 26,
                                color: COLORS.white,
                                textAlign: 'center',
                            }}
                            allowFontScaling={false}
                            numberOfLines={2}>
                            $ {utils.trimDecimals(utils.getTotalCouponsValue(selectedCoupons), 2)} {'\n'}
                            <Text
                                style={{
                                    color: COLORS.white,
                                    fontWeight: 'normal',
                                    fontSize: 12,
                                }}
                                allowFontScaling={false}>
                                Coupons Value{'   '}
                            </Text>
                        </Text>
                    </View>
                </View>
            }
            {selectedCoupons.length == 0 &&
            <View>
                {/* Terms of Service and Privacy Policy */}
                <View
                    style={{
                        flexDirection: 'column',
                        marginTop: SIZES.padding,
                        justifyContent: 'center',
                        marginBottom:0
                    }}>
                    <Text
                        style={{
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                        allowFontScaling={false}>
                        By continuing, you agree to our
                    </Text>

                    <Text
                        style={{
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                        allowFontScaling={false}>
                        <Text
                            style={{
                                color: COLORS.white,
                                textDecorationLine: 'underline',
                            }}
                            allowFontScaling={false}
                            onPress={() => Linking.openURL(constants.APP_LINKS.TERMS_OF_USE)}>
                            Terms of Use
                        </Text>{' '}
                        and{' '}
                        <Text
                            style={{
                                color: COLORS.white,
                                textDecorationLine: 'underline',
                            }}
                            allowFontScaling={false}
                            onPress={() => Linking.openURL(constants.APP_LINKS.PRIVACY_POLICY)}>
                            Privacy Policy
                        </Text>
                    </Text>
                </View>
                {/* Copyright */}
                <View
                    style={{
                        flexDirection: 'column',
                        marginTop: SIZES.padding,
                        justifyContent: 'center',
                    }}>
                    <Text
                        style={{
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                        allowFontScaling={false}>
                        © Spectiv Labs Inc.
                    </Text>
                </View>
            </View>
}

            <Modal
                animationType="fade"
                transparent={true}
                visible={showErrorModal}
                onRequestClose={() => setShowErrorModal(false)}
            >
                <View
                    style={{
                        flex: 1,
                        justifyContent: "flex-end",
                        backgroundColor: COLORS.transparentBlack5,
                    }}
                >
                    {/* Transparent Background */}
                    <TouchableWithoutFeedback
                        onPress={() => {
                            setShowErrorModal(false);
                        }}
                    >
                        <View
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                        />
                    </TouchableWithoutFeedback>
                    <View
                        style={{
                            left: 0,
                            // bottom: modalY,
                            flexDirection: "column",
                            width: "100%",
                            height: 200,
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: 41,
                            paddingBottom: 44,
                            // padding: SIZES.padding,
                            borderRadius: 20,
                            backgroundColor: COLORS.white,
                            shadowColor: COLORS.black,
                            shadowOffset: { width: 0, height: 1 },
                            shadowOpacity: 0.2,
                            shadowRadius: 30,
                            elevation: 3,
                        }}
                    >
                        <View
                            style={{
                                width: SIZES.width,
                                padding: SIZES.radius,
                                borderRadius: SIZES.radius,
                                backgroundColor: COLORS.white,
                                height: SIZES.height / 4,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <View>
                                <Text
                                    style={{
                                        fontSize: 20,
                                        color: COLORS.darkTextColor,
                                        fontWeight: "bold",
                                        marginBottom: 10,
                                        textAlign: "center",
                                    }}
                                    allowFontScaling={false}
                                >
                                    {errorTitle}
                                </Text>
                                <Text
                                    style={{
                                        fontSize: 16,
                                        lineHeight: 24,
                                        color: COLORS.darkTextColor,
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                    allowFontScaling={false}
                                >
                                    {errorMessage}
                                </Text>
                            </View>
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginTop: 20,
                                    width: "80%",
                                }}
                            >
                                <TouchableOpacity
                                    style={{
                                        alignItems: "center",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                        backgroundColor: COLORS.primary,
                                        alignItems: "center",
                                        height: 50,
                                        width: 120,
                                        borderRadius: 25,
                                        borderWidth: SIZES.borderWidth2,
                                        borderColor: COLORS.white,
                                        shadowColor: COLORS.gray5,
                                        backgroundColor: COLORS.primary,
                                        color: COLORS.white,
                                        fontSize: 18,
                                    }}
                                    onPress={() => {
                                        setShowErrorModal(false);
                                        window.open("https://web.gitsy.app/", "_self");
                                    }}
                                >
                                    <Text
                                        style={{ color: COLORS.white }}
                                        allowFontScaling={false}
                                    >
                                        Close
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>

            {/* Coupon Delete Modal */}
            <Modal
                animationType="fade"
                transparent={true}
                visible={showDeleteModal}
                onRequestClose={() => setShowDeleteModal(false)}
            >
                <View
                    style={{
                        flex: 1,
                        justifyContent: "flex-end",
                        backgroundColor: COLORS.transparentBlack5,
                    }}
                >
                    {/* Transparent Background */}
                    <TouchableWithoutFeedback
                        onPress={() => {
                            setShowDeleteModal(false);
                        }}
                    >
                        <View
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                        />
                    </TouchableWithoutFeedback>
                    <View
                        style={{
                            left: 0,
                            // bottom: modalY,
                            flexDirection: "column",
                            width: "100%",
                            height: 200,
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: 41,
                            paddingBottom: 44,
                            // padding: SIZES.padding,
                            borderRadius: 20,
                            backgroundColor: COLORS.white,
                            shadowColor: COLORS.black,
                            shadowOffset: { width: 0, height: 1 },
                            shadowOpacity: 0.2,
                            shadowRadius: 30,
                            elevation: 3,
                        }}
                    >
                        <View
                            style={{
                                width: SIZES.width,
                                padding: SIZES.radius,
                                borderRadius: SIZES.radius,
                                backgroundColor: COLORS.white,
                                height: SIZES.height / 4,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <View>
                                <Text
                                    style={{
                                        fontSize: 20,
                                        color: COLORS.darkTextColor,
                                        fontWeight: "bold",
                                        marginBottom: 10,
                                        textAlign: "center",
                                    }}
                                    allowFontScaling={false}
                                >
                                    Delete Coupon
                                </Text>
                                <Text
                                    style={{
                                        fontSize: 16,
                                        lineHeight: 24,
                                        color: COLORS.darkTextColor,
                                        fontWeight: "normal",
                                        textAlign: "center",
                                    }}
                                    allowFontScaling={false}
                                >
                                    Are you sure you want to delete this coupon?
                                </Text>
                            </View>
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginTop: 20,
                                    width: "80%",
                                }}
                            >
                                <TouchableOpacity
                                    style={{
                                        alignItems: "center",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                        backgroundColor: COLORS.blue,
                                        alignItems: "center",
                                        height: 50,
                                        width: 120,
                                        borderRadius: 25,
                                        borderWidth: SIZES.borderWidth2,
                                        borderColor: COLORS.white,
                                        shadowColor: COLORS.gray5,
                                        backgroundColor: COLORS.primary,
                                        color: COLORS.white,
                                        fontSize: 18,
                                    }}
                                    onPress={() => {
                                        setShowDeleteModal(false);
                                    }}
                                >
                                    <Text
                                        style={{ color: COLORS.white }}
                                        allowFontScaling={false}
                                    >
                                        Cancel
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{
                                        alignItems: "center",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                        backgroundColor: COLORS.primary,
                                        alignItems: "center",
                                        height: 50,
                                        width: 120,
                                        borderRadius: 25,
                                        borderWidth: SIZES.borderWidth2,
                                        borderColor: COLORS.white,
                                        shadowColor: COLORS.gray5,
                                        backgroundColor: COLORS.primary,
                                        color: COLORS.white,
                                        fontSize: 18,
                                    }}
                                    onPress={() => {
                                        apiController.deleteCoupon(deleteItem.serialized_gs1);
                                        listCoupons.splice(listCoupons.indexOf(deleteItem), 1);
                                        setShowDeleteModal(false);
                                    }}
                                >
                                    <Text
                                        style={{ color: COLORS.white }}
                                        allowFontScaling={false}
                                    >
                                        Yes
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>

            {/* Coupon Detail Modal */}
            {showCouponDetailModal && (
                <Modal
                    animationType="none"
                    visible={showCouponDetailModal}
                    transparent={true}
                    onRequestClose={() => {
                        setShowCouponDetailModal(false);
                        setItem(null);
                        setValidFor(310);
                        setFetchCode(".");
                        setDescriptionView(false);
                        setShowBarcode(false);
                        //setRedeemd(false);
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            backgroundColor: COLORS.transparentBlack7,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {/* Transparent Background */}
                        <TouchableWithoutFeedback
                            onPress={() => {
                                setShowCouponDetailModal(false);
                                setItem(null);
                                setValidFor(310);
                                setFetchCode(".");
                                setDescriptionView(false);
                                setShowBarcode(false);
                                //setRedeemd(false);
                            }}
                        >
                            <View
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}
                            />
                        </TouchableWithoutFeedback>

                        <View
                            style={{
                                // left: 0,
                                // top: modalY,
                                flexDirection: "column",
                                width: 330,
                                height: 530,
                                //padding: SIZES.padding,
                                borderRadius: 10,
                                backgroundColor: COLORS.white,
                            }}
                        >
                            {!descriptionView ? (
                                <>
                                    {/* Header */}
                                    <View
                                        style={{
                                            height: 50,
                                            borderTopLeftRadius: 10,
                                            borderTopRightRadius: 10,
                                            backgroundColor: COLORS.primary,
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: 32,
                                                color: COLORS.white,
                                            }}
                                            allowFontScaling={false}
                                        >
                                            {item.title}
                                            {/* <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 32,
                                                    color: COLORS.white,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                $ {utils.trimDecimals(item.dollar_amount, 2)}
                                            </Text> */}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            borderRadius: SIZES.radius,
                                            backgroundColor: COLORS.white,
                                            height: 120,
                                            alignItems: "center",
                                            marginBottom: SIZES.radius,
                                            marginTop: 10,
                                        }}
                                    >
                                        {/* Image */}
                                        <Image
                                            source={{ uri: item.mobile_image_url }}
                                            resizeMode="contain"
                                            style={{
                                                height: 115,
                                                width: 115,
                                                marginRight: SIZES.radius,
                                                marginLeft: SIZES.radius,
                                            }}
                                        />

                                        {/* Info */}
                                        <View
                                            style={{
                                                flex: 1,
                                                height: "100%",
                                                flexDirection: "column",
                                                //paddingVertical: SIZES.radius,
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <View style={{}}>
                                                {/* Name */}
                                                <Text
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 18,
                                                        lineHeight: 22,
                                                        color: COLORS.gray4,
                                                    }}
                                                    allowFontScaling={false}
                                                    numberOfLines={1}
                                                >
                                                    {item.title}
                                                </Text>
                                                {/* Description */}
                                                <TouchableWithoutFeedback
                                                    onPress={() => setDescriptionView(true)}
                                                >
                                                    <View
                                                        style={{
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontWeight: "normal",
                                                                fontSize: 18,
                                                                lineHeight: 22,
                                                                color: COLORS.gray4,
                                                                marginRight: 10,
                                                                width: "80%",
                                                            }}
                                                            allowFontScaling={false}
                                                            numberOfLines={4}
                                                            ellipsizeMode="tail"
                                                        >
                                                            {item.description}
                                                        </Text>
                                                        <TouchableOpacity
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                justifyContent: "center",
                                                                marginRight: 5,
                                                            }}
                                                            onPress={() => setDescriptionView(true)}
                                                        >
                                                            <Image
                                                                source={icons.down_arrow}
                                                                style={{
                                                                    alignSelf: "center",
                                                                    tintColor: COLORS.gray2,
                                                                    width: 24,
                                                                    height: 24,
                                                                    transform: [{ rotate: "-90deg" }],
                                                                }}
                                                            />
                                                        </TouchableOpacity>
                                                    </View>
                                                </TouchableWithoutFeedback>
                                            </View>
                                        </View>
                                    </View>
                                    {/* Coupon Cut */}
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            marginTop: 10,
                                        }}
                                    >
                                        <Image
                                            source={images.notch}
                                            resizeMode="cover"
                                            style={{
                                                height: 10,
                                                width: 20,
                                                marginLeft: -5,
                                                opacity: 1,
                                                tintColor: COLORS.transparentBlack7,
                                                transform: [{ rotate: "-90deg" }],
                                            }}
                                        />
                                        <Image
                                            source={images.horizontal_dashes}
                                            resizeMode="cover"
                                            style={{
                                                flex: 1,
                                                opacity: 0.5,
                                                tintColor: COLORS.transparentBlack7,
                                                marginHorizontal: -5,
                                                //transform: [{rotate: '90deg'}],
                                            }}
                                        />
                                        <Image
                                            source={images.notch}
                                            resizeMode="cover"
                                            style={{
                                                height: 10,
                                                width: 20,
                                                marginRight: -5,
                                                opacity: 1,
                                                tintColor: COLORS.transparentBlack7,
                                                transform: [{ rotate: "90deg" }],
                                            }}
                                        />
                                    </View>
                                    {!redeemed ? (
                                        <View
                                            style={{
                                                //flex: 1,
                                                flexDirection: "column",
                                                height: 250,
                                                width: "100%",
                                                //justifyContent: 'space-evenly',
                                            }}
                                        /* onPress={() => setRedeemd(true)} */
                                        >
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 18,
                                                    color: COLORS.primarybodyColor,
                                                    textAlign: "center",
                                                    marginBottom: 10,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                {/* Show this {showBarcode ? 'barcode' : "QR code"} to cashier{'\n'} and redeem your coupons */}
                                                Show this screen to cashier{"\n"} and redeem your
                                                coupons
                                            </Text>
                                            {/* Barcode or QRCode */}
                                            {showBarcode && !redeemed ? (
                                                <TouchableOpacity
                                                    style={{
                                                        //padding: SIZES.radius,
                                                        //width: SIZES.width * 0.6,
                                                        alignItems: "center",
                                                    }}
                                                /* onPress={() => setRedeemd(true)} */
                                                >
                                                    <Barcode
                                                        /* style={{
                                                                                      //padding: SIZES.radius,
                                                                                      alignContent: 'center',
                                                                                      height: SIZES.height * 0.1,
                                                                                  }} */
                                                        format="CODE128"
                                                        lineColor={
                                                            fetchCode != "."
                                                                ? COLORS.black
                                                                : COLORS.transparent
                                                        }
                                                        value={fetchCode}
                                                        //width={SIZES.width * 0.6}
                                                        height={SIZES.height * 0.1}
                                                    />
                                                    <Text
                                                        style={{
                                                            textAlign: "auto",
                                                            marginTop: 10,
                                                            color: COLORS.transparentBlack7,
                                                            paddingLeft: 30,
                                                            paddingRight: 30,
                                                            opacity: 0.4,
                                                        }}
                                                        allowFontScaling={false}
                                                        numberOfLines={1}
                                                        ellipsizeMode="clip"
                                                    >
                                                        ------------------------------------------------------------------------
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            fontWeight: "normal",
                                                            fontSize: 18,
                                                            color: COLORS.primarybodyColor,
                                                            textAlign: "center",
                                                            marginTop: 10,
                                                        }}
                                                        allowFontScaling={false}
                                                    >
                                                        Or type this code to redeem{"\n"}your coupons
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: COLORS.primaryTextColor,
                                                            fontWeight: "bold",
                                                            fontSize: 30,
                                                            textAlign: "center",
                                                            marginTop: 10,
                                                        }}
                                                        allowFontScaling={false}
                                                    /* onPress={() => setRedeemd(true)} */
                                                    >
                                                        {fetchCode}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            ///fontFamily: 'Ubuntu-Regular',
                                                            fontStyle: "italic",
                                                            fontSize: 14,
                                                            color: COLORS.primarybodyColor,
                                                            textAlign: "center",
                                                            marginBottom: 10,
                                                        }}
                                                        allowFontScaling={false}
                                                    >
                                                        Once coupon code is scanned, it may take{"\n"} few
                                                        moments for redemption confirmation.
                                                    </Text>
                                                </TouchableOpacity>
                                            ) : (
                                                <View
                                                    style={{
                                                        //padding: SIZES.radius,
                                                        //width: SIZES.width * 0.6,
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <TouchableOpacity /* onPress={() => setRedeemd(true)} */
                                                        disabled={true}
                                                    >
                                                        <Barcode
                                                            /* style={{
                                                                                                //padding: SIZES.radius,
                                                                                                alignContent: 'center',
                                                                                                height: SIZES.height * 0.1,
                                                                                            }} */
                                                            format="CODE128"
                                                            lineColor={COLORS.black}
                                                            value={item.serialized_gs1}
                                                            width={1}
                                                            height={SIZES.height * 0.1}
                                                        />

                                                        {/* <QRCode
                                                            //QR code value
                                                            value={item.serialized_gs1}
                                                            //size of QR Code
                                                            size={180}
                                                            //logo={images.logo_03}
                                                            color={COLORS.black}
                                                            quietZone={10}
                                                        /> */}
                                                    </TouchableOpacity>

                                                    <Text
                                                        style={{
                                                            color: COLORS.primary,
                                                            textDecorationLine: "underline",
                                                            fontWeight: "bold",
                                                            fontSize: 18,
                                                            textAlign: "center",
                                                            marginTop: 14,
                                                        }}
                                                        allowFontScaling={false}
                                                        onPress={() => {
                                                            setShowBarcode(!showBarcode);
                                                            apiController.fetchCodeForCoupon(
                                                                vc,
                                                                item.serialized_gs1,
                                                                setFetchCode,
                                                                setValidFor
                                                            );
                                                        }}
                                                    >
                                                        Not able to scan code?
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            ///fontFamily: 'Ubuntu-Regular',
                                                            fontStyle: "italic",
                                                            fontSize: 14,
                                                            color: COLORS.primarybodyColor,
                                                            textAlign: "center",
                                                            marginBottom: 10,
                                                        }}
                                                        allowFontScaling={false}
                                                    >
                                                        Once coupon code is scanned, it may take{"\n"} few
                                                        moments for redemption confirmation.
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    ) : (
                                        <View
                                            style={{
                                                //flex: 1,
                                                flexDirection: "column",
                                                height: 250,
                                                width: "100%",
                                                //justifyContent: 'space-evenly',
                                            }}
                                        >
                                            <Image
                                                source={images.blue_tick_circle}
                                                resizeMode="cover"
                                                style={{
                                                    height: 100,
                                                    width: 100,
                                                    marginTop: 10,
                                                    opacity: 1,
                                                    tintColor: COLORS.primary,
                                                    alignSelf: "center",
                                                    tintColor: COLORS.green,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 28,
                                                    color: COLORS.darkTextColor,
                                                    textAlign: "center",
                                                    marginBottom: 10,
                                                    marginTop: 10,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                Your redemption{"\n"} is successful.
                                            </Text>
                                            <Image
                                                source={images.horizontal_dashes}
                                                resizeMode="cover"
                                                style={{
                                                    marginTop: 10,
                                                    opacity: 0.5,
                                                    tintColor: COLORS.transparentBlack7,
                                                    marginHorizontal: -5,
                                                    //transform: [{rotate: '90deg'}],
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 18,
                                                    color: COLORS.primaryTextColor,
                                                    textAlign: "center",
                                                    marginTop: 10,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                Redeemed Coupons -{" "}
                                                <Text
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 18,
                                                        color: COLORS.primaryTextColor,
                                                    }}
                                                    allowFontScaling={false}
                                                >
                                                    1
                                                </Text>
                                            </Text>
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 18,
                                                    color: COLORS.primaryTextColor,
                                                    textAlign: "center",
                                                    marginTop: 5,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                Total Value -{" "}
                                                <Text
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 18,
                                                        color: COLORS.primaryTextColor,
                                                    }}
                                                    allowFontScaling={false}
                                                >
                                                    ${utils.trimDecimals(item.dollar_amount, 2)}
                                                </Text>
                                            </Text>
                                        </View>
                                    )}
                                </>
                            ) : (
                                <View
                                    style={{
                                        //flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        width: 330,
                                        height: 530,
                                    }}
                                >
                                    {/* Header */}
                                    <View
                                        style={{
                                            height: 50,
                                            borderTopLeftRadius: 10,
                                            borderTopRightRadius: 10,
                                            backgroundColor: COLORS.primary,
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: 24,
                                                color: COLORS.white,
                                            }}
                                            allowFontScaling={false}
                                        >
                                            Expires on{" "}
                                            <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 24,
                                                    color: COLORS.white,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                {utils.getMmDd(item.valid_till)}
                                            </Text>
                                        </Text>
                                    </View>
                                    <TouchableOpacity
                                        style={{
                                            flexDirection: "row",
                                            paddingVertical: SIZES.base,
                                            paddingHorizontal: SIZES.radius,
                                            borderRadius: SIZES.radius,
                                            height: 40,
                                            width: 100,
                                            marginTop: 15,
                                            alignItems: "center",
                                        }}
                                        onPress={() => setDescriptionView(false)}
                                    >
                                        <Image
                                            source={icons.back}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                tintColor: COLORS.primaryTextColor,
                                            }}
                                        />

                                        <Text
                                            style={{
                                                marginLeft: SIZES.base,
                                                fontWeight: "normal",
                                                color: COLORS.primaryTextColor,
                                            }}
                                            allowFontScaling={false}
                                        >
                                            Back
                                        </Text>
                                    </TouchableOpacity>

                                    <ScrollView
                                        scrollEnabled={true}
                                        style={{ marginBottom: 20, marginTop: 10 }}
                                    >
                                        <View
                                            style={{
                                                marginHorizontal: 20,
                                            }}
                                        >
                                            {/* Image */}
                                            <Image
                                                source={{ uri: item.mobile_image_url }}
                                                resizeMode="contain"
                                                style={{
                                                    height: 115,
                                                    width: 115,
                                                    marginRight: SIZES.radius,
                                                    marginLeft: SIZES.radius,
                                                    alignSelf: "center",
                                                }}
                                            />
                                            {/* Name */}
                                            <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                    lineHeight: 22,
                                                    color: COLORS.gray4,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                {item.title}
                                            </Text>
                                            {/* Description */}
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 18,
                                                    lineHeight: 22,
                                                    color: COLORS.gray4,
                                                    marginTop: 5,
                                                    marginBottom: 10,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                {item.description}
                                            </Text>
                                            {/* <Text
                          style={{
                            fontFamily: 'WorkSans-Medium',
                            fontSize: 18,
                            color: COLORS.gray4,
                            marginTop:10
                          }}
                          allowFontScaling={false}
                          numberOfLines={1}>
                          EXP: {utils.getMmDd(item.valid_till)}
                        </Text> */}
                                            <Image
                                                source={images.horizontal_dashes}
                                                resizeMode="cover"
                                                style={{
                                                    flex: 1,
                                                    opacity: 0.5,
                                                    tintColor: COLORS.transparentBlack7,
                                                    marginHorizontal: -20,
                                                    //transform: [{rotate: '90deg'}],
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                    lineHeight: 22,
                                                    color: COLORS.gray4,
                                                    marginBottom: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                Terms & Conditions:
                                            </Text>
                                            <HyperLink
                                                onPress={(url) => Linking.openURL(url)}
                                                onLongPress={(url) => Linking.openURL(url)}
                                                linkStyle={{ color: COLORS.primary }}
                                                allowFontScaling={false}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: "normal",
                                                        fontSize: 16,
                                                        lineHeight: 22,
                                                        color: COLORS.gray4,
                                                        textAlign: "left",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}
                                                    allowFontScaling={false}
                                                >
                                                    {item.terms}
                                                </Text>
                                            </HyperLink>
                                        </View>
                                    </ScrollView>
                                </View>
                            )}
                        </View>
                        <TouchableOpacity
                            style={{
                                width: 53,
                                height: 53,
                                borderWidth: 1,
                                borderRadius: 27,
                                borderColor: COLORS.gray2,
                                justifyContent: "center",
                                marginTop: 18,
                            }}
                            onPress={() => {
                                setShowCouponDetailModal(false);
                                setItem(null);
                                setValidFor(310);
                                setFetchCode(".");
                                setDescriptionView(false);
                                setShowBarcode(false);
                                //setRedeemd(false);
                            }}
                        >
                            <Image
                                source={icons.cross}
                                style={{
                                    alignSelf: "center",
                                    tintColor: COLORS.white,
                                    width: 36,
                                    height: 36,
                                }}
                            />
                        </TouchableOpacity>
                    </View>
                </Modal>
            )}

            {/* Bundle Action Modal */}
            {showBundleAction && (
                <Modal
                    animationType="none"
                    visible={showBundleAction}
                    transparent={true}
                    onRequestClose={() => {
                        setShowBundleAction(false);
                        setItem(null);
                        setValidFor(310);
                        setFetchCode(".");
                        setDescriptionView(false);
                        setShowBarcode(false);
                        //setRedeemd(false);
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            backgroundColor: COLORS.transparentBlack7,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {/* Transparent Background */}
                        <TouchableWithoutFeedback
                            onPress={() => {
                                setShowBundleAction(false);
                                setItem(null);
                                setValidFor(310);
                                setBundleId(".");
                                setExpandedBundleId(".")
                                setDescriptionView(false);
                                setShowBarcode(false);
                            }}
                        >
                            <View
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}
                            />
                        </TouchableWithoutFeedback>

                        <View
                            style={{
                                // left: 0,
                                // top: modalY,
                                flexDirection: "column",
                                width: 330,
                                height: 530,
                                //padding: SIZES.padding,
                                borderRadius: 10,
                                backgroundColor: COLORS.white,
                            }}
                        >
                            {!descriptionView ? (
                                <>
                                    {/* Header */}
                                    <View
                                        style={{
                                            height: 70,
                                            borderTopLeftRadius: 10,
                                            borderTopRightRadius: 10,
                                            backgroundColor: COLORS.primary,
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: 16,
                                                color: COLORS.white,
                                            }}
                                            allowFontScaling={false}
                                        >
                                            Selected coupons value
                                            </Text>
                                            <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 34,
                                                    color: COLORS.white,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                $ {utils.trimDecimals(selectedCouponsValue, 2)}
                                            </Text>
                                        
                                    </View>
                                    
                                    {/* Coupon Cut */}
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            //marginTop: 10,
                                        }}
                                    >
                                        <Image
                                            source={images.notch}
                                            resizeMode="cover"
                                            style={{
                                                height: 10,
                                                width: 20,
                                                marginLeft: -5,
                                                opacity: 1,
                                                tintColor: COLORS.transparentBlack7,
                                                transform: [{ rotate: "-90deg" }],
                                            }}
                                        />
                                        {/* <Image
                                            source={images.horizontal_dashes}
                                            resizeMode="cover"
                                            style={{
                                                flex: 1,
                                                opacity: 0.5,
                                                tintColor: COLORS.transparentBlack7,
                                                marginHorizontal: -5,
                                                //transform: [{rotate: '90deg'}],
                                            }}
                                        /> */}
                                        <Image
                                            source={images.notch}
                                            resizeMode="cover"
                                            style={{
                                                height: 10,
                                                width: 20,
                                                marginRight: -5,
                                                opacity: 1,
                                                tintColor: COLORS.transparentBlack7,
                                                transform: [{ rotate: "90deg" }],
                                            }}
                                        />
                                    </View>
                                    {!redeemed ? (
                                        <View
                                            style={{
                                                //flex: 1,
                                                flexDirection: "column",
                                                height: 250,
                                                width: "100%",
                                                //justifyContent: 'space-evenly',
                                            }}
                                        /* onPress={() => setRedeemd(true)} */
                                        >
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 18,
                                                    color: COLORS.primarybodyColor,
                                                    textAlign: "center",
                                                    marginBottom: 10,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                {/* Show this {showBarcode ? 'barcode' : "QR code"} to cashier{'\n'} and redeem your coupons */}
                                                Show this screen to cashier{"\n"} and redeem your
                                                coupons
                                            </Text>
                                            {/* Barcode or QRCode */}
                                            {showBarcode && !redeemed ? (
                                                <TouchableOpacity
                                                    style={{
                                                        //padding: SIZES.radius,
                                                        //width: SIZES.width * 0.6,
                                                        alignItems: "center",
                                                    }}
                                                /* onPress={() => setRedeemd(true)} */
                                                >
                                                    <Barcode
                                                        /* style={{
                                                                                      //padding: SIZES.radius,
                                                                                      alignContent: 'center',
                                                                                      height: SIZES.height * 0.1,
                                                                                  }} */
                                                        format="CODE128"
                                                        lineColor={
                                                            bundleId != "."
                                                                ? COLORS.black
                                                                : COLORS.transparent
                                                        }
                                                        value={bundleId}
                                                        width={1}
                                                        height={SIZES.height * 0.1}
                                                    />
                                                    <Text
                                                        style={{
                                                            textAlign: "auto",
                                                            marginTop: 10,
                                                            color: COLORS.transparentBlack7,
                                                            paddingLeft: 30,
                                                            paddingRight: 30,
                                                            opacity: 0.4,
                                                        }}
                                                        allowFontScaling={false}
                                                        numberOfLines={1}
                                                        ellipsizeMode="clip"
                                                    >
                                                        ------------------------------------------------------------------------
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            fontWeight: "normal",
                                                            fontSize: 18,
                                                            color: COLORS.primarybodyColor,
                                                            textAlign: "center",
                                                            marginTop: 10,
                                                        }}
                                                        allowFontScaling={false}
                                                    >
                                                        Or type this code to redeem{"\n"}your coupons
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            color: COLORS.primaryTextColor,
                                                            fontWeight: "bold",
                                                            fontSize: 30,
                                                            textAlign: "center",
                                                            marginTop: 10,
                                                            numberOfLines:2,
                                                            width:'90%'
                                                            
                                                        }}
                                                        allowFontScaling={false}
                                                    /* onPress={() => setRedeemd(true)} */
                                                    >
                                                        {bundleId}
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            ///fontFamily: 'Ubuntu-Regular',
                                                            fontStyle: "italic",
                                                            fontSize: 14,
                                                            color: COLORS.primarybodyColor,
                                                            textAlign: "center",
                                                            marginBottom: 10,
                                                        }}
                                                        allowFontScaling={false}
                                                    >
                                                        Once coupon code is scanned, it may take{"\n"} few
                                                        moments for redemption confirmation.
                                                    </Text>
                                                </TouchableOpacity>
                                            ) : (
                                                <View
                                                    style={{
                                                        //padding: SIZES.radius,
                                                        //width: SIZES.width * 0.6,
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <TouchableOpacity /* onPress={() => setRedeemd(true)} */
                                                        disabled={true}
                                                    >
                                                        <Barcode
                                                            /* style={{
                                                                                                //padding: SIZES.radius,
                                                                                                alignContent: 'center',
                                                                                                height: SIZES.height * 0.1,
                                                                                            }} */
                                                            format="CODE128"
                                                            lineColor={
                                                                bundleId != "."
                                                                    ? COLORS.black
                                                                    : COLORS.transparent
                                                            }
                                                            value={bundleId}
                                                            width={1}
                                                            height={SIZES.height * 0.1}
                                                        />

                                                        {/* <QRCode
                                                            //QR code value
                                                            value={item.serialized_gs1}
                                                            //size of QR Code
                                                            size={180}
                                                            //logo={images.logo_03}
                                                            color={COLORS.black}
                                                            quietZone={10}
                                                        /> */}
                                                    </TouchableOpacity>

                                                    <Text
                                                        style={{
                                                            color: COLORS.primary,
                                                            textDecorationLine: "underline",
                                                            fontWeight: "bold",
                                                            fontSize: 18,
                                                            textAlign: "center",
                                                            marginTop: 14,
                                                        }}
                                                        allowFontScaling={false}
                                                        onPress={() => {
                                                            setShowBarcode(!showBarcode);
                                                        }}
                                                    >
                                                        Not able to scan code?
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            ///fontFamily: 'Ubuntu-Regular',
                                                            fontStyle: "italic",
                                                            fontSize: 14,
                                                            color: COLORS.primarybodyColor,
                                                            textAlign: "center",
                                                            marginBottom: 10,
                                                        }}
                                                        allowFontScaling={false}
                                                    >
                                                        Once coupon code is scanned, it may take{"\n"} few
                                                        moments for redemption confirmation.
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    ) : (
                                        <View
                                            style={{
                                                //flex: 1,
                                                flexDirection: "column",
                                                height: 250,
                                                width: "100%",
                                                //justifyContent: 'space-evenly',
                                            }}
                                        >
                                            <Image
                                                source={images.blue_tick_circle}
                                                resizeMode="cover"
                                                style={{
                                                    height: 100,
                                                    width: 100,
                                                    marginTop: 10,
                                                    opacity: 1,
                                                    tintColor: COLORS.primary,
                                                    alignSelf: "center",
                                                    tintColor: COLORS.green,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 28,
                                                    color: COLORS.darkTextColor,
                                                    textAlign: "center",
                                                    marginBottom: 10,
                                                    marginTop: 10,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                Your redemption{"\n"} is successful.
                                            </Text>
                                            <Image
                                                source={images.horizontal_dashes}
                                                resizeMode="cover"
                                                style={{
                                                    marginTop: 10,
                                                    opacity: 0.5,
                                                    tintColor: COLORS.transparentBlack7,
                                                    marginHorizontal: -5,
                                                    //transform: [{rotate: '90deg'}],
                                                }}
                                            />
                                            {/* 
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 18,
                                                    color: COLORS.primaryTextColor,
                                                    textAlign: "center",
                                                    marginTop: 10,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                Redeemed Coupons -{" "}
                                                <Text
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 18,
                                                        color: COLORS.primaryTextColor,
                                                    }}
                                                    allowFontScaling={false}
                                                >
                                                    {selectedCouponsCount}
                                                </Text>
                                            </Text>
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 18,
                                                    color: COLORS.primaryTextColor,
                                                    textAlign: "center",
                                                    marginTop: 5,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                Total Value -{" "}
                                                <Text
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: 18,
                                                        color: COLORS.primaryTextColor,
                                                    }}
                                                    allowFontScaling={false}
                                                >
                                                    ${utils.trimDecimals(selectedCouponsValue, 2)}
                                                </Text>
                                            </Text>
                                             */}
                                        </View>
                                    )}
                                </>
                            ) : (
                                <View
                                    style={{
                                        //flex: 1,
                                        flexDirection: "column",
                                        width: "100%",
                                        width: 330,
                                        height: 530,
                                    }}
                                >
                                    {/* Header */}
                                    <View
                                        style={{
                                            height: 50,
                                            borderTopLeftRadius: 10,
                                            borderTopRightRadius: 10,
                                            backgroundColor: COLORS.primary,
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontWeight: "normal",
                                                fontSize: 24,
                                                color: COLORS.white,
                                            }}
                                            allowFontScaling={false}
                                        >
                                            Expires on{" "}
                                            <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 24,
                                                    color: COLORS.white,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                {utils.getMmDd(item.valid_till)}
                                            </Text>
                                        </Text>
                                    </View>
                                    <TouchableOpacity
                                        style={{
                                            flexDirection: "row",
                                            paddingVertical: SIZES.base,
                                            paddingHorizontal: SIZES.radius,
                                            borderRadius: SIZES.radius,
                                            height: 40,
                                            width: 100,
                                            marginTop: 15,
                                            alignItems: "center",
                                        }}
                                        onPress={() => setDescriptionView(false)}
                                    >
                                        <Image
                                            source={icons.back}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                tintColor: COLORS.primaryTextColor,
                                            }}
                                        />

                                        <Text
                                            style={{
                                                marginLeft: SIZES.base,
                                                fontWeight: "normal",
                                                color: COLORS.primaryTextColor,
                                            }}
                                            allowFontScaling={false}
                                        >
                                            Back
                                        </Text>
                                    </TouchableOpacity>

                                    <ScrollView
                                        scrollEnabled={true}
                                        style={{ marginBottom: 20, marginTop: 10 }}
                                    >
                                        <View
                                            style={{
                                                marginHorizontal: 20,
                                            }}
                                        >
                                            {/* Image */}
                                            <Image
                                                source={{ uri: item.mobile_image_url }}
                                                resizeMode="contain"
                                                style={{
                                                    height: 115,
                                                    width: 115,
                                                    marginRight: SIZES.radius,
                                                    marginLeft: SIZES.radius,
                                                    alignSelf: "center",
                                                }}
                                            />
                                            {/* Name */}
                                            <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                    lineHeight: 22,
                                                    color: COLORS.gray4,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                {item.title}
                                            </Text>
                                            {/* Description */}
                                            <Text
                                                style={{
                                                    fontWeight: "normal",
                                                    fontSize: 18,
                                                    lineHeight: 22,
                                                    color: COLORS.gray4,
                                                    marginTop: 5,
                                                    marginBottom: 10,
                                                }}
                                                allowFontScaling={false}
                                            >
                                                {item.description}
                                            </Text>
                                            {/* <Text
                          style={{
                            fontFamily: 'WorkSans-Medium',
                            fontSize: 18,
                            color: COLORS.gray4,
                            marginTop:10
                          }}
                          allowFontScaling={false}
                          numberOfLines={1}>
                          EXP: {utils.getMmDd(item.valid_till)}
                        </Text> */}
                                            <Image
                                                source={images.horizontal_dashes}
                                                resizeMode="cover"
                                                style={{
                                                    flex: 1,
                                                    opacity: 0.5,
                                                    tintColor: COLORS.transparentBlack7,
                                                    marginHorizontal: -20,
                                                    //transform: [{rotate: '90deg'}],
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 18,
                                                    lineHeight: 22,
                                                    color: COLORS.gray4,
                                                    marginBottom: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                Terms & Conditions:
                                            </Text>
                                            <HyperLink
                                                onPress={(url) => Linking.openURL(url)}
                                                onLongPress={(url) => Linking.openURL(url)}
                                                linkStyle={{ color: COLORS.primary }}
                                                allowFontScaling={false}
                                            >
                                                <Text
                                                    style={{
                                                        fontWeight: "normal",
                                                        fontSize: 16,
                                                        lineHeight: 22,
                                                        color: COLORS.gray4,
                                                        textAlign: "left",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}
                                                    allowFontScaling={false}
                                                >
                                                    {item.terms}
                                                </Text>
                                            </HyperLink>
                                        </View>
                                    </ScrollView>
                                </View>
                            )}
                        </View>
                        <TouchableOpacity
                            style={{
                                width: 53,
                                height: 53,
                                borderWidth: 1,
                                borderRadius: 27,
                                borderColor: COLORS.gray2,
                                justifyContent: "center",
                                marginTop: 18,
                            }}
                            onPress={() => {
                                setShowBundleAction(false);
                                setItem(null);
                                setValidFor(310);
                                setBundleId(".");
                                setExpandedBundleId(".")
                                setDescriptionView(false);
                                setShowBarcode(false);
                                //setRedeemd(false);
                            }}
                        >
                            <Image
                                source={icons.cross}
                                style={{
                                    alignSelf: "center",
                                    tintColor: COLORS.white,
                                    width: 36,
                                    height: 36,
                                }}
                            />
                        </TouchableOpacity>
                    </View>
                </Modal>
            )}
            </ImageBackground>
        </KeyboardAvoidingView>
    );
}

export default Home

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        //justifyContent: 'center',
        backgroundColor: COLORS.appBackground
    },
    inputContainer: {
        width: '80%'
    },
    phoneInput: {
        flexDirection: 'row',
        height: 50,
        paddingHorizontal: SIZES.radius,
        borderRadius: 25,
        borderWidth: 1,
        borderColor: COLORS.lightGray3,
        backgroundColor: COLORS.white,
        color: COLORS.primaryTextColor,
    },
    input: {
        backgroundColor: 'white',
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 10,
        marginTop: 5,
    },
    buttonContainer: {
        width: '60%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 40,
    },
    button: {
        backgroundColor: '#0782F9',
        width: '100%',
        padding: 15,
        borderRadius: 10,
        alignItems: 'center',
    },
    buttonOutline: {
        backgroundColor: 'white',
        marginTop: 5,
        borderColor: '#0782F9',
        borderWidth: 2,
    },
    buttonText: {
        color: 'white',
        fontWeight: '700',
        fontSize: 16,
    },
    buttonOutlineText: {
        color: '#0782F9',
        fontWeight: '700',
        fontSize: 16,
    },
});