import {getAuth} from "firebase/auth";
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBxqh1HYUKZfj9FPOxKrrImxnMpvuCReY8",
    authDomain: "bndl-prod.firebaseapp.com",
    projectId: "bndl-prod",
    storageBucket: "bndl-prod.appspot.com",
    messagingSenderId: "371711267444",
    appId: "1:371711267444:web:6ba3b349691a4f6c803721",
    measurementId: "G-6QJT08FV6W"
  };
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export {app, auth, firestore}
