import {utils} from '.';
import {constants, dummyData} from '../constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {getAuth, signInWithCustomToken} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import { auth, firestore } from '../firebase'
import { collection, addDoc, setDoc, doc, onSnapshot, updateDoc } from 'firebase/firestore'
import base64 from 'react-native-base64'

async function getCountryData(
  setCountryData,
  setCountryCode,
  setSelectedCountry,
  countryCode
) {
  try {
    ////console.log("FETCHING_COUNTRIES: ");
    const response = await fetch(
      'https://countriesnow.space/api/v0.1/countries/codes',
    ); //("https://restcountries.eu/rest/v2/all")
    const responseJson = await response.json();
    let areaData = responseJson.data.map(item => {
      return {
        id: item.code,
        label: item.name,
        value: item.dial_code,
        flag: `https://countryflagsapi.com/png/${item.code}`,//`https://www.countryflags.io/${item.code}/flat/64.png`,
      };
    });

    setCountryData(areaData);
    /* Set default country as US */
    if (areaData.length > 0) {
      let defaultData = areaData.filter(a => a.id == countryCode);

      if (defaultData.length > 0) {
        setSelectedCountry(defaultData[0].id);
        setCountryCode(defaultData[0].value);
      }
    }
  } catch (error) {
    //console.error(error);
  }
}

async function getXAccessToken() {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  if(appBaseUrl == null)
  {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('ACCESS_TOKEN_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  else{
    //console.log('ACCESS_TOKEN_API_BASE_URL: ', appBaseUrl);
  }
  //const appBaseUrl = constants.API_BASE_URLS.BASE_URL;
  

  const API = appBaseUrl + constants.API_END_POINTS.GET_X_ACCESS_TOKEN;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': constants.APP_ACCESS_KEY,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      access_key: constants.APP_ACCESS_KEY,
      secret_key: constants.APP_SECRET_KEY,
    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log("X_ACCESS_TOKEN_RESPONSE: ", responseJson["x-access-token"]);
      utils.storeStringData(
        constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
        responseJson['x-access-token'],
      );
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}
async function getWalletKey(setWalletKey) {
  const accessToken = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN);
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  if(appBaseUrl == null)
  {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('ACCESS_TOKEN_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  else{
    //console.log('ACCESS_TOKEN_API_BASE_URL: ', accessToken);
  }
  //const appBaseUrl = constants.API_BASE_URLS.BASE_URL;
  

  const API = appBaseUrl + constants.API_END_POINTS.WALLETS;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': accessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log("WALLET_KEY: ", responseJson.data[0].public_key_pem);
      setWalletKey(responseJson.data[0].public_key_pem);
      // utils.storeStringData(
      //   constants.ASYNC_STORAGE_DATA.WALLET_KEY,
      //   responseJson.data[0].public_key_pem,
      // );
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}

async function initiateVcAuth(phoneNumber, setConfirmation, setTxnId, setIndicatorVisible, setShowErrorModal, setErrorMessage, setErrorTitle) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  if(appBaseUrl == null)
  {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('INITIATE_TCB_AUTH_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  else{
    //console.log('INITIATE_TCB_AUTH_ACCESS_TOKEN: ', accessToken);
  }
  //const appBaseUrl = constants.API_BASE_URLS.BASE_URL;
  

  const API = appBaseUrl + constants.API_END_POINTS.INITIATE_AUTH;
  //console.log('INITIATE_VC_AUTH_API_ENDPOINT: ', API);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      phone_number: phoneNumber,
    }),
  })
    .then(response => response.json())
    
    .then(responseJson => {
      //console.log("INITIATE__VC_AUTH_RESPONSE: ", responseJson);
      setIndicatorVisible(false);
      if (responseJson.status == 'success') {
      setTxnId(responseJson.txn_id);
      setConfirmation(true);
      }
      else if (responseJson.status == 'error'){
        setShowErrorModal(true);
        setErrorMessage(responseJson.message);
        setErrorTitle(responseJson.code.replace('_', ' '));
        switch (responseJson.code) {
          case "OTP_EXISTS":
            //setErrorMessage('OTP already in progress. Please try after '+responseJson.duration_left+' seconds');
            setErrorMessage('Please wait at least '+responseJson.duration_left+ ' seconds before requesting another OTP.');
            break;
          default:
            break;
        }
        
      }
      
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}

async function verifyVcAuth(txnId, otp, confirm, setOtpVerified, setVc, setIndicatorVisible,setInvalidOtpModal, setShowErrorModal, setErrorMessage, setErrorTitle) {
  //console.log('VERIFY_TCB_AUTH : ', txnId+":"+otp);
  //const accessToken = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN);
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  if(appBaseUrl == null)
  {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('VERIFY_TCB_AUTH_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  else{
    //console.log('VERIFY_TCB_AUTH_ACCESS_TOKEN: ', accessToken);
  }
  //const appBaseUrl = constants.API_BASE_URLS.BASE_URL;
  

  const API = appBaseUrl + constants.API_END_POINTS.VERIFY_AUTH;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    //'x-access-token': accessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      txn_id: txnId,
      otp: otp
    }),
  })
    .then(response => response.json())
    
    .then(responseJson => {
      //console.log("VERIFY_VC_AUTH_RESPONSE: ", responseJson);
      //setIndicatorVisible(false);
      if (responseJson.status == 'success') {
        setVc(responseJson.vc);
        setOtpVerified(true);
      }
      else if (responseJson.status == 'error') {
        setOtpVerified(false);
        setErrorTitle(responseJson.code.replace('_', ' '));
        switch (responseJson.code) {
          case "INVALID_OTP":
            //setErrorMessage('OTP already in progress. Please try after '+responseJson.duration_left+' seconds');
            setInvalidOtpModal(true)

            break;
          case "ACCOUNT_BLOCKED":
            setShowErrorModal(true);
            setErrorMessage(responseJson.message);
          case "INVALID_TXN_ID":
            setShowErrorModal(true);
            setErrorMessage("Failed to verify phone. Please check the phone number and try again.");
          case "EXPIRED_OTP":
            setShowErrorModal(true);
            setErrorMessage("The OTP you entered has expired. Please try again.");

          default:
            setShowErrorModal(true);
            setErrorMessage("Failed to verify phone. Please check the phone number and try again.");
            break;
        }

      }
      
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}
async function deposit(phoneNumber, baseGs1, bundleOf, setDeposited, setShowCouponExistsAlert, setErrorTitle, setErrorMessage)
{
  //console.log('PHONE_NUMBER', phoneNumber);
  let phone_number = phoneNumber.replace('+', '+4444');
  //console.log('PHONE_NUMBER_4444', phone_number);

  const API = "https://try.gitsy.app/api/temp/initiate_tcb_auth";
  const vc = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PROVIDER_VC);
  //console.log('DEPOSIT_GITSY_VC', vc);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    //'x-access-token': accessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      phone_number: phone_number,
      base_gs1: baseGs1,
      bundle_of: bundleOf,
      gitsy_vc: vc
    }),
  })
    .then(response => response.json())

    .then(responseJson => {
      //console.log("DEPOSIT_RESPONSE: ", responseJson);
      if (responseJson.status == 'success') {
        setDeposited(true);
      }
      else if (responseJson.status == 'error') {
        setDeposited(false);
        if (responseJson.error == 'COUPON_EXISTS') {
          setShowCouponExistsAlert(true);
          setErrorTitle(responseJson.error.replaceAll('_', ' '));
          setErrorMessage(responseJson.message);
          
        }
        else if (responseJson.error == 'COUPON_ALREADY_USED') {
          setShowCouponExistsAlert(true);
          setErrorTitle(responseJson.error.replaceAll('_', ' '));
          setErrorMessage(responseJson.message);
        }

      }

    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });

}
async function deleteCoupon(gs1, setDeleted)
{
  //console.log('DELETE_GS1', gs1);

  const API = "https://try.gitsy.app/api/temp/delete_coupon";
  /* const vc = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PROVIDER_VC);
  console.log('DEPOSIT_GITSY_VC', vc); */
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    //'x-access-token': accessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      gs1: gs1,
    }),
  })
    .then(response => response.json())

    .then(responseJson => {
      //console.log("DELETE_COUPON_RESPONSE: ", responseJson);
      if (responseJson.status == 'success') {
        setDeleted(true);
      }
      else if (responseJson.status == 'error') {
        setDeleted(false);

      }

    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });

}
async function registerUserWithPhone(phoneNumber, setFirebaseToken, setErrorMessage, setErrorTitle, setShowErrorModal) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  if (appBaseUrl == null) {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('VERIFY_TCB_AUTH_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  else {
    //console.log('VERIFY_TCB_AUTH_ACCESS_TOKEN: ', accessToken);
  }
  //const appBaseUrl = constants.API_BASE_URLS.BASE_URL;


  const API = appBaseUrl + constants.API_END_POINTS.REGISTER_USER;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    //'x-access-token': accessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      phone_number: phoneNumber,
    }),
  })
    .then(response => response.json())

    .then(responseJson => {
      //console.log("REGISTER_USER_RESPONSE: ", responseJson);
      if (responseJson.status == 'success') {
        setFirebaseToken(responseJson.token);
      }
      else if (responseJson.status == 'error') {
        setErrorTitle("Registration Failed");
        setErrorMessage("Failed to register user.");
        setShowErrorModal(true);
        /* switch (responseJson.code) {
          case "TOKEN_FAILED":
            //setErrorMessage('OTP already in progress. Please try after '+responseJson.duration_left+' seconds');
            setInvalidOtpModal(true)
            break;
          case "USER_FAILED":
            setShowErrorModal(true);
            setErrorMessage(responseJson.message);
          case "INVALID_PHONE":
            setShowErrorModal(true);
            setErrorMessage(responseJson.message);
          case "REG_ERROR":
            setShowErrorModal(true);
            setErrorMessage("Failed to verify phone. Please check the phone number and try again.");

          default:
            break;
        } */

      }

    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}

async function initiateAuth(phoneNumber, firebaseToken, setUser, setErrorMessage, setErrorTitle, setShowErrorModal) {
  /* const confirmation = await auth().signInWithPhoneNumber(phoneNumber, true);
  setIndicatorVisible(false);
  setConfirmation(confirmation); */
  //let token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY3MjcyODE5OSwiZXhwIjoxNjcyNzMxNzk5LCJpc3MiOiJibmRsLWFwcEBhcHBzcG90LmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJibmRsLWFwcEBhcHBzcG90LmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiJCZ045ZzFaZFlOVFVwQ3JqbDFmUzZ1eHd1N1AyIn0.LbZsOsav63IoApuOhRlUJP-O0jRCP7PWi3GA6cH2kcY2fs-UiZ590BWEj5cyVKwNPLh9vDZgIdb6fA6_PbbnWRdDz520lXYwx2prqFDSSb4JKZsPkri1Ev6PXNgy7MQvZuhekdAzPzasM-3Q1pxFKv-4m6e-amJoNlleXtx2hFeYAWppk8QyK2G-lhRFD--YA8kywXubpgaT9O2bRppLXChwVEBiQtHl1rHNgiUCjjbcwakBuezTTO5couhkoMFAEE21XzhE7AXs2pdGD-EAeU6ycY9WdvpZVchWiPXjIgLw9gB6_9R0dH7qc0-dM1imRAQxjTqLo9raJS-jcdHPTw'
  const vc = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PROVIDER_VC);
  const didUser = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
  //console.log('USER_CREDENTIAL_VC: ', vc);
  const auth = getAuth();
  signInWithCustomToken(auth, firebaseToken)
  .then((userCredential) => {
    // Signed in
    var user = userCredential.user;
    utils.storeStringData(constants.ASYNC_STORAGE_DATA.USER_ID, userCredential.user.uid);
    //getAuthState();
    const docref = setDoc(doc(firestore, "consumers_pwa/" + userCredential.user.uid ), { vc: vc, did_user: didUser, phone_number: phoneNumber });
    setUser(user);
    //console.log('USER_CREDENTIAL: ', userCredential.user.uid);
    // ...
  })
  .catch((error) => {
    setErrorTitle("Registration Failed");
    setErrorMessage("Failed to register user.");
    setShowErrorModal(true);
    //console.log('Error: ', error);
    // ...
  });
  //console.log('Phone number: ', phoneNumber + ' : ' + confirmation);
}
async function verifyAuth(otp, confirm, setOtpVerified, setIndicatorVisible,setInvalidOtpModal) {
  var user = auth().currentUser;
  if (user) {
      setIndicatorVisible(false);
      setOtpVerified(true);
      getAuthState()
    }else{
      verifyAuthProcess(otp, confirm, setOtpVerified, setIndicatorVisible,setInvalidOtpModal)
    }
}

async function verifyAuthProcess(otp, confirm, setOtpVerified, setIndicatorVisible,setInvalidOtpModal) {
  try {
    await confirm.confirm(otp);
    setIndicatorVisible(false);
    setOtpVerified(true);
    getAuthState();
  } catch (error) {
    // alert('Invalid OTP');
    setInvalidOtpModal(true)
    setIndicatorVisible(false);
  }
}
async function getAuthState() {
  auth().onAuthStateChanged(user => {
    if (user) {
      //console.log('Splash: Current User: ' + user.uid);
      utils.storeStringData(constants.ASYNC_STORAGE_DATA.USER_ID, user.uid);
      utils.storeStringData(
        constants.ASYNC_STORAGE_DATA.USER_SIGNED_IN,
        'true',
      );
    } else {
      utils.storeStringData(
        constants.ASYNC_STORAGE_DATA.USER_SIGNED_IN,
        'false',
      );
      ////console.log('Splash: No User Signed in ' + authenticated);
    }
  });
}
async function getFirebaseUserData(setUserData) {
  auth().onAuthStateChanged(user => {
    if (user) {
      //console.log('Splash: Current User: ' + user.phoneNumber);
      setUserData(user);
      utils.storeStringData(constants.ASYNC_STORAGE_DATA.USER_ID, user.uid);
      utils.storeStringData(
        constants.ASYNC_STORAGE_DATA.USER_SIGNED_IN,
        'true',
      );
    } else {
      setUserData(null);
      utils.storeStringData(
        constants.ASYNC_STORAGE_DATA.USER_SIGNED_IN,
        'false',
      );
      ////console.log('Splash: No User Signed in ' + authenticated);
    }
  });
}
async function getConsumerData(userId, setConsumerData) {
  const firestore = getFirestore()
  const consumerData = await firestore()
    .collection('consumers')
    .doc(userId)
    .get();
  setConsumerData(consumerData);
  //console.log('user_id :', userId);
  //console.log('consumer_id: ',consumerData['consumer_id'] + ' : ' + consumerData['phone_number'],);
}

async function getAllValidCoupons(vc, setCoupons, setCouponsOthers, setShowLoader, setDisplayNoCouponView) {
  //const accessToken = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN);
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  if(appBaseUrl == null)
  {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('COUPONS_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  else{
    //console.log('COUPONS_API_ACCESS_TOKEN: ', accessToken);
  }

  const API = appBaseUrl + constants.API_END_POINTS.GET_ALL_VALID_COUPONS;
  
  //console.log("GET_ALL_VALID_COUPONS_PROVIDER_VC: ", vc);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    //'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      vcs: vc,
    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log("GET_ALL_VALID_COUPONS_RESPONSE: ", responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      if (responseJson.status == 'success') {
        
        /* utils.storeStringData(
          constants.ASYNC_STORAGE_DATA.COUPONS_OTHERS,
          JSON.stringify(responseJson.coupons_others),
        ); */
        let coupons = responseJson.coupons.map(item => {
          return {
            description: item.description,
            brand: item.brand == undefined? '':item.brand,
            //coupon_id: item.coupon_id,
            valid_till: item.valid_till,
            valid_till_human_readable: item.valid_till_human_readable,
            serialized_gs1: item.serialized_gs1,
            title: item.title,
            mobile_image_url: item.mobile_image_url == undefined? 'https://gitsy.s3.ap-south-1.amazonaws.com/campaign_image/bacon.jpeg':item.mobile_image_url,//dummyData.couponImageUrls[0],
            desktop_image_url: item.desktop_image_url == undefined? 'https://gitsy.s3.ap-south-1.amazonaws.com/campaign_image/bacon.jpeg':item.desktop_image_url,
            terms: item.terms,
            dollar_amount: item.dollar_amount,
            expires_in: utils.calculateDaysToGo(
              item.valid_till,
              item.valid_till_human_readable,
            ),
          };
        });

        if (coupons.length>0) {
          coupons.sort((a, b) => {
          return a.expires_in - b.expires_in || b.dollar_amount - a.dollar_amount;
          });
        }
        let unique_coupons = [];
        responseJson.bundled_coupons.forEach(offer => {
          //bundle_gs1s.push(offer.base_gs1);
          console.log("GET_ALL_VALID_COUPONS_SORTED: ", offer.base_gs1);
          //const result = _.findWhere(coupons, { serialized_gs1: "8112004979004815169455243107121903"});
          //console.log("RESULT: ", result);
        });
        //saveCouponsToDb(coupons);
        utils.storeStringData(
          constants.ASYNC_STORAGE_DATA.COUPONS,
          JSON.stringify(coupons),
        );
        //coupons.reverse();
        setCoupons(coupons);
        setCouponsOthers(coupons);
        setDisplayNoCouponView(coupons.length>0 ? false:true);
        setShowLoader(false);
      }
      else{
        setCoupons([]);
        setCouponsOthers([]);
        setDisplayNoCouponView(true);
        setShowLoader(false);
      }
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
      setDisplayNoCouponView(true);
      setShowLoader(false);
    });
}

async function getBundleIdforBundle(
  coupons,
  setBundleId,
  setFetchCode,
  setExpandedBundleId,
  setValidFor,
  setQrData,
  setQrProcessed,
  setQrError
  //setIndicatorVisible,
) {
  //console.log('GET_BUNDLE_ID_FOR_MASTER_BARCODE_COUPON: ', coupons);
  //const appBaseUrl = await AsyncStorage.getItem(ASYNC_STORAGE_DATA.APP_BASE_URL);
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  if(appBaseUrl == null)
  {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('COUPONS_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  const API = appBaseUrl + constants.API_END_POINTS.GET_BUNDLE_ID_BARCODE;
  // const API = 'http://localhost:5001/bndl-app/us-central1/app/api/consumer/bundle'
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const vc = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.PROVIDER_VC,
  );
  let couponIds = [];
  coupons.forEach(coupon => {
    couponIds.push(coupon.serialized_gs1);
  });
  // couponIds=["6767556576"]
  // console.log("🚀 ~ file: ApiController.js ~ line 474 ~ couponIds", couponIds)
  //console.log('GET_BUNDLE_ID_FOR_MASTER_BARCODE_COUPON_IDS: ', couponIds);
  ////console.log("GET_BUNDLE_ID_FOR_MASTER_BARCODE_PROVIDER_VC: ", vc);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': xAccessToken,
  }; 
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      vcs: vc,
      coupon_ids: couponIds,
    }), //post body
  })
    .then(response => response.json())
    // console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
          // console.error("JSON resp", responseJson?.error);

      //alert(JSON.stringify(responseJson));
      //console.log('GET_BUNDLE_ID_FOR_MASTER_BARCODE_RESPONSE: ', responseJson);
      let successResponse= []
      
      if (responseJson.error) {
        //setMasterBundleCoupons(masterBundleCoupons);
        //setQrData([]);
        setBundleId('');
        setExpandedBundleId('');
        setFetchCode('');
        //setQrProcessed(true)
        //setQrError(responseJson.error)
        //setValidFor(responseJson.status === "success"? 300:0);
        //setIndicatorVisible(false);
      }
      else{
        responseJson.forEach((item)=>{
          if(item.status === 'success'){
            successResponse.push(item)
          }
          })
          ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
          if (successResponse.length > 0) {
            
            //setQrData(successResponse)
            //setMasterBundleCoupons(masterBundleCoupons);
            setBundleId(responseJson[0].bundle_id);
            setExpandedBundleId(responseJson[0].expanded_bundle_id);
            setFetchCode(responseJson[0].bundle_id);
            //setQrProcessed(true)
            setValidFor(responseJson[0].status === "success"? 310:0);
            //setIndicatorVisible(false);
          }
          else{
            // console.log('object',responseJson[0].message)
            //setMasterBundleCoupons(masterBundleCoupons);
            //setQrData([]);
            setBundleId('');
            setExpandedBundleId('');
            setFetchCode('');
            //setQrProcessed(true)
            //setQrError(responseJson[0]?.message)
            // setQrError(responseJson[0])
            //setValidFor(responseJson.status === "success"? 300:0);
            //setIndicatorVisible(false);
            
          }

      }
      
      
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //setQrData([]);
      setBundleId('');
      setExpandedBundleId('');
      setFetchCode('');
      //setQrProcessed(true)
      //setValidFor(responseJson.status === "success"? 300:0);
      //setIndicatorVisible(false);
      //console.error('JSON error', error);
    });
}

async function saveBundleForLater(
  coupons,
  bundleName
) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  let consumerId = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.CONSUMER_ID);
  const API = appBaseUrl + constants.API_END_POINTS.CREATE_BUNDLE;
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const uid = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.USER_ID,
  );
  /* let couponArr = [];
  coupons.forEach(coupon => {
    couponArr.push(coupon);
  }); */
  //console.log('CREATE_BUNDLE_COUPONS: ', coupons);
  ////console.log("GET_BUNDLE_ID_FOR_MASTER_BARCODE_PROVIDER_VC: ", vc);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      bundle_name: bundleName,
      consumer_id: consumerId,
      coupons: coupons,
      uid: uid
    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log('CREATE_BUNDLE_RESPONSE: ', responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      if (responseJson.status == 'success') {
        

        //setMasterBundleCoupons(masterBundleCoupons);
        // setBundleId(responseJson.bundle_id);
        // setExpandedBundleId(responseJson.expanded_bundle_id);
        // setFetchCode(responseJson.fetch_code);
        // setValidFor(responseJson.status === "success"? 250:0);
        //setShowLoader(false);
      }
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}

async function fetchBundles(setBundles) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  let consumerId = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.CONSUMER_ID);
  const API = appBaseUrl + constants.API_END_POINTS.FETCH_BUNDLES;
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const uid = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.USER_ID,
  );
  //console.log("BUNDLE_USER_ID", uid);
  /* let couponArr = [];
  coupons.forEach(coupon => {
    couponArr.push(coupon);
  }); */
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      consumer_id: consumerId,
      uid: uid,
    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log('FETCH_BUNDLE_RESPONSE: ', responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      if (responseJson.status == 'success') {
        var bundleArr = responseJson.bundles;
        if (responseJson.bundles.length>0) {
          bundleArr.sort((a, b) => {
          return b.create_timestamp._seconds - a.create_timestamp._seconds;
          });
        }
        
        setBundles(bundleArr.length>0? bundleArr: []);
        
        //setShowLoader(false);
      }
      else setBundles([]);
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}
async function updateBundleCoupons(
  coupons,
  id, setValidFor, setRefreshBundles, name = null
) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  const API = appBaseUrl + constants.API_END_POINTS.UPDATE_BUNDLE;
  //console.log('CREATE_BUNDLE_COUPONS: ', coupons);
  ////console.log("GET_BUNDLE_ID_FOR_MASTER_BARCODE_PROVIDER_VC: ", vc);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      id: id,
      coupons: coupons,
      name: name
    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log('UPDATE_BUNDLE_RESPONSE: ', responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      if (responseJson.status == 'success') {
        setValidFor(0);
        setRefreshBundles(true);
      }
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}
async function getBundleIdForBarcode2(
  couponsOthers,
  setBarcode2BundleId,
  setBarcode2BundleCoupons,
  setShowLoader,
) {
  //const appBaseUrl = await AsyncStorage.getItem(ASYNC_STORAGE_DATA.APP_BASE_URL);
  const appBaseUrl = constants.API_BASE_URLS.BASE_URL;
  //console.log('API_BASE_URL: ', appBaseUrl);

  const API = appBaseUrl + constants.API_END_POINTS.GET_BUNDLE_ID_BARCODE;
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const vc = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.PROVIDER_VC,
  );
  let couponIds = [];
  couponsOthers.forEach(coupon => {
    couponIds.push(coupon.coupon_id);
  });
  //console.log('GET_BUNDLE_ID_FOR_BARCODE2_COUPON_IDS: ', couponIds);
  ////console.log("GET_BUNDLE_ID_FOR_MASTER_BARCODE_PROVIDER_VC: ", vc);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': constants.APP_ACCESS_KEY,
    'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      vc: vc,
      coupon_ids: couponIds,
    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log('GET_BUNDLE_ID_FOR_BARCODE2_RESPONSE: ', responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      if (responseJson.status == 'success') {
        let barcode2BundleCoupons = responseJson.coupons_others.map(item => {
          return {
            coupon_id: item.coupon_id,
            valid_till: item.valid_till,
            valid_till_human_readable: item.valid_till_human_readable,
            image_url: item.image_url,
            dollar_amount: item.dollar_amount,
            expires_in: utils.calculateDaysToGo(
              item.valid_till,
              item.valid_till_human_readable,
            ),
          };
        });

        setBarcode2BundleCoupons(barcode2BundleCoupons);
        setBarcode2BundleId(responseJson.bundle_id);
        setShowLoader(false);
      }
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}
async function fetchCodeForCoupon(vc, serializedGs1, setFetchCode, setValidFor) {
  const accessToken = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN);
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  //const vc = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PROVIDER_VC);
  if(appBaseUrl == null)
  {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('FETCH_CODE_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  else{
    //console.log('FETCH_CODE_API_ACCESS_TOKEN: ', accessToken);
  }
  //const appBaseUrl = constants.API_BASE_URLS.BASE_URL;
  

  const API = appBaseUrl + constants.API_END_POINTS.FETCH_CODE_FOR_COUPON;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': accessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      serialized_gs1: serializedGs1,
      vc: vc,
    }),
  })
    .then(response => response.json())
    
    .then(responseJson => {
      //console.log("FETCH_CODE_RESPONSE: ", responseJson);
      setFetchCode(responseJson.status === "success"? responseJson.fetch_code:"");
      setValidFor(responseJson.status === "success"? 310:0);
      
      // utils.storeStringData(
      //   constants.ASYNC_STORAGE_DATA.WALLET_KEY,
      //   responseJson.data[0].public_key_pem,
      // );
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
    });
}

async function updateProfile(fullName, email, gender, age, setStatus) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  let fbUserid = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.USER_ID);
  //console.log('FB_USER_ID_UPDATE_PROFILE: ', fbUserid);
  const API = appBaseUrl + constants.API_END_POINTS.UPDATE_PROFILE;
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const vc = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.PROVIDER_VC,
  );
  /* let couponArr = [];
  coupons.forEach(coupon => {
    couponArr.push(coupon);
  }); */
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      user_id: fbUserid,
      name: fullName,
      email: email,
      gender: gender,
      age: age,

    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log('UPDATE PROFILE_RESPONSE: ', responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      if (responseJson.status == 'success') {
        
        //setBundles(responseJson.bundles);
        
        setStatus('success');
      }
      else{
        setStatus('failed');
      }
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
      setStatus('failed');
    });
}
async function deleteProfile(fullName, email, gender, age, setStatus) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  let fbUserid = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.USER_ID);
  //console.log('FB_USER_ID_UPDATE_PROFILE: ', fbUserid);
  const API = appBaseUrl + constants.API_END_POINTS.UPDATE_PROFILE;
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const vc = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.PROVIDER_VC,
  );
  /* let couponArr = [];
  coupons.forEach(coupon => {
    couponArr.push(coupon);
  }); */
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      user_id: fbUserid,
      name: fullName,
      email: email,
      gender: gender,
      age: age,

    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log('UPDATE PROFILE_RESPONSE: ', responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      if (responseJson.status == 'success') {
        
        //setBundles(responseJson.bundles);
        
        setStatus('success');
      }
      else{
        setStatus('failed');
      }
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      //console.error('JSON error', error);
      setStatus('failed');
    });
}

async function updatePushToken(fcmToken, setInfoUpdated) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  if (appBaseUrl == null) {
    appBaseUrl = constants.API_BASE_URLS.BASE_URL;
    //console.log('VERIFY_TCB_AUTH_API_BASE_URL_LOCAL: ', appBaseUrl);
  }
  let fbUserid = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.USER_ID);
  //console.log('FB_USER_ID_UPDATE_FCM_TOKEN: ', fbUserid);
  const API = appBaseUrl + constants.API_END_POINTS.UPDATE_PUSH_TOKEN;
  
  const vc = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PROVIDER_VC);
  let didUser = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
  //console.log('DID_USER_UPDATE_FCM_TOKEN: ', didUser);
  /* let couponArr = [];
  coupons.forEach(coupon => {
    couponArr.push(coupon);
  }); */
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  let DeviceInfo = await utils.getDeviceInfo()
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      user_id: fbUserid,
      fcm_token: fcmToken,
      did_user: didUser,
      vc: vc,
      ...DeviceInfo
    }), //post body
  })
    .then(response => response.json())
    
    .then(responseJson => {
      
      //console.log('USER_UPDATE_FCM_TOKEN_RESPONSE: ', responseJson);
      if (responseJson.status == 'success') {
        setInfoUpdated(true);
        
        //('success');
      }
      else{
        //setStatus('failed');
      }
    })
    .catch(error => {
      
      //setStatus('failed');
    }); 
}

async function saveCouponsToDb(coupons) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  const API = appBaseUrl + constants.API_END_POINTS.SAVE_COUPONS;
  const vc = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PROVIDER_VC);
  let didUser = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
  //console.log('DID_USER_UPDATE_FCM_TOKEN: ', didUser);
  
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      coupons: coupons,
      did_user: didUser,
    }), //post body
  })
    .then(response => response.json())
    
    .then(responseJson => {
      
      //console.log('SAVE_COUPONS_RESPONSE: ', responseJson);
      if (responseJson.status == 'success') {
        
        
        //('success');
      }
      else{
        //setStatus('failed');
      }
    })
    .catch(error => {
      
      //setStatus('failed');
    }); 
}
async function fetchNotifications(setNotificationsData, setShowLoader, setDisplayNoNotificationsView) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  let consumerId = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.CONSUMER_ID);
  const API = appBaseUrl + constants.API_END_POINTS.FETCH_NOTIFICATIONS;
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const vc = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.PROVIDER_VC,
  );
  let didUser = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
  /* let couponArr = [];
  coupons.forEach(coupon => {
    couponArr.push(coupon);
  }); */
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      did_user: didUser,
    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log('FETCH_NOTIFICATIONS_RESPONSE: ', responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      setShowLoader(false);
      if (responseJson.status == 'success') {
        var notificationsArr = responseJson.notifications;
        notificationsArr.sort((a, b) => {
          return b.action_timestamp - a.action_timestamp;
      });
      //console.log('FETCH_NOTIFICATIONS_RESPONSE_SORTED: ', notificationsArr);
        setNotificationsData(notificationsArr.length>0? notificationsArr: []);
        setDisplayNoNotificationsView(notificationsArr.length>0 ? false:true);
        
        //setShowLoader(false);
      }
      else{
        setShowLoader(false);
        setDisplayNoNotificationsView(true);
      }
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      setShowLoader(false);
      setDisplayNoNotificationsView(true);
      //console.error('JSON error', error);
    });
}

async function fetchReceipts(setReceiptsData, setShowLoader, setDisplayNoReceiptsView) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  let consumerId = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.CONSUMER_ID);
  const API = appBaseUrl + constants.API_END_POINTS.FETCH_RECEIPTS;
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const vc = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.PROVIDER_VC,
  );
  let didUser = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
  /* let couponArr = [];
  coupons.forEach(coupon => {
    couponArr.push(coupon);
  }); */
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      consumer_id: consumerId,
    }), //post body
  })
    .then(response => response.json())
    //console.error("JSON resp", response);

    //If response is in json then in success
    .then(responseJson => {
      //alert(JSON.stringify(responseJson));
      //console.log('FETCH_RECEIOTS_RESPONSE: ', responseJson);
      ////console.log("GET_ALL_VALID_COUPONS_OTHERS_RESPONSE: ", responseJson.coupons_others);
      setShowLoader(false);
      if (responseJson.status == 'success') {
        var receiptsArr = responseJson.receipts;
        receiptsArr.sort((a, b) => {
          return b.receipt_timestamp - a.receipt_timestamp;
      });
      //console.log('FETCH_RECEIPTS_RESPONSE_SORTED: ', receiptsArr);
        setReceiptsData(receiptsArr.length>0? receiptsArr: []);
        setDisplayNoReceiptsView(receiptsArr.length>0 ? false:true);
        
        //setShowLoader(false);
      }
      else{
        setShowLoader(false);
        setDisplayNoReceiptsView(true);
      }
    })
    //If response is not in json then in error
    .catch(error => {
      //alert(JSON.stringify(error));
      setShowLoader(false);
      setDisplayNoReceiptsView(true);
      //console.error('JSON error', error);
    });
}

async function getProfile(setConsumerData) {
  let appBaseUrl = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.APP_BASE_URL);
  let userId = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.USER_ID);
  const API = appBaseUrl + constants.API_END_POINTS.GET_PROFILE;
  const xAccessToken = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.X_ACCESS_TOKEN,
  );
  const vc = await AsyncStorage.getItem(
    constants.ASYNC_STORAGE_DATA.PROVIDER_VC,
  );
  let didUser = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': xAccessToken,
  };
  fetch(API, {
    method: 'POST', //Request Type
    headers,
    body: JSON.stringify({
      user_id: userId,
    }), //post body
  })
    .then(response => response.json())

    .then(responseJson => {
      //console.log('PROFILE_RESPONSE: ', responseJson);
      
      if (responseJson.status == 'success') {
        setConsumerData(responseJson.consumer);
      }
    })
    .catch(error => {
      //alert(JSON.stringify(error));
      setShowLoader(false);
      //console.error('JSON error', error);
    });
}
const apiController = {
  getCountryData,
  getXAccessToken,
  getWalletKey,
  initiateVcAuth,
  verifyVcAuth,
  registerUserWithPhone,
  initiateAuth,
  verifyAuth,
  getAuthState,
  getFirebaseUserData,
  getConsumerData,
  getAllValidCoupons,
  getBundleIdforBundle,
  getBundleIdForBarcode2,
  fetchCodeForCoupon,
  saveBundleForLater,
  fetchBundles,
  updateBundleCoupons,
  updateProfile,
  deleteProfile,
  updatePushToken,
  fetchNotifications,
  fetchReceipts,
  getProfile,
  deposit,
  deleteCoupon,
};

export default apiController;
