import { KeyboardAvoidingView, StyleSheet, Text, TextInput, TouchableOpacity, View, Image, Modal, TouchableWithoutFeedback, Linking, ImageBackground } from 'react-native'
import React from 'react'
import { COLORS, FONTS, images, SIZES, ANIMATIONS, dummyData, icons, theme, constants } from '../constants'
import { useNavigation } from '@react-navigation/native'
import { SkypeIndicator } from 'react-native-indicators'
/* import { FormInput } from '../components'
import { utils } from '../utils'  */
/* import { TextBorderButton } from '../components'*/
import { apiController } from '../utils'

const Signin = () => {
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [countryData, setCountryData] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [countryCode, setCountryCode] = React.useState('+1');
    const [phoneNumberError, setPhoneNumberError] = React.useState(
        constants.NO_ERROR_MESSAGE,
    );
    const [indicatorVisible, setIndicatorVisible] = React.useState(false);
    const [confirmation, setConfirmation] = React.useState(false);
    const [txnId, setTxnId] = React.useState('');
    // Error Modal View
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorTitle, setErrorTitle] = React.useState('');
    const [deposited, setDeposited] = React.useState(false);
    const [showCouponExistsAlert, setShowCouponExistsAlert] = React.useState(false);

    const navigation = useNavigation()
    React.useEffect(() => {
        //apiController.getCountryData(setCountryData, setCountryCode, setSelectedCountry)
        ////console.log('AUTH_TXN_ID = ', txnId);
        if (confirmation) {
            setConfirmation(false);
            navigation.navigate('Otp', {
                phoneNumber: countryCode + phoneNumber,
                txnId: txnId,
                confirm: confirmation,
            });
        }
    }, [confirmation]);

    function isEnableSignIn() {
        return phoneNumber != '' && phoneNumberError == constants.NO_ERROR_MESSAGE && phoneNumber.length == 10;
    }
    return (
      <KeyboardAvoidingView
        style={styles.container}>
        <ImageBackground
          source={images.frame}
          resizeMode="cover"
          style={{
            flex: 1, width: '100%', height: '100%', position: 'absolute',
            alignItems: 'center', justifyContent: 'center',
          }}>
          <Image
            source={images.logo_02}
            resizeMode="contain"
            style={{
              height: 100,
              width: 120,
            }}
          />

          <Text
            style={{
              textAlign: 'center',
              color: COLORS.white,
              marginBottom: 20,
              fontSize: 18,
            }}
            allowFontScaling={false}>
            Enter your 10 digit phone number
          </Text>
          <View
            style={{
              flexDirection: 'row',
              height: 84,
              paddingHorizontal: 10,
              borderColor: COLORS.transparentWhite1,
              borderWidth: 9,
              borderRadius: 42,
              backgroundColor: COLORS.transparent,
              alignItems: 'center',
            }}>

            <TextInput
              placeholder="10 digit phone number"
              /* value={email}
              onChangeText={text => setEmail(text)} */
              style={styles.phoneInput}
              placeholderTextColor={COLORS.gray2}
              secureTextEntry={false}
              keyboardType={"phone-pad"}
              autoCapitalize={'none'}
              maxLength={10}
              onChangeText={text => setPhoneNumber(text)}
            />
          </View>
          {/* Sign In with Phone Number */}
          <TouchableOpacity
            style={{
              alignItems: 'center',
              alignSelf: 'center',
              justifyContent: 'center',
              backgroundColor: COLORS.primary,
              borderWidth: 4,
              height: 50,
              width: 150,
              alignItems: 'center',
              marginTop: SIZES.padding,
              borderRadius: 50,
              borderWidth: SIZES.borderWidth3,
              borderColor: COLORS.white,
              opacity: isEnableSignIn() ? 1 : 0.5,
            }}
            disabled={isEnableSignIn() ? false : true}
            onPress={() => {
              setIndicatorVisible(true);
              apiController.initiateVcAuth(
                countryCode + phoneNumber,
                setConfirmation,
                setTxnId,
                setIndicatorVisible,
                setShowErrorModal,
                setErrorMessage,
                setErrorTitle
              );

              //console.log("SIGN_IN_PATHNAME: ", window.location.pathname);
              if (phoneNumber != null && window.location.pathname != '/' && window.location.pathname.includes("8112")) {
                //console.log("SIGN_IN_PATHNAME_TRUE: ", window.location.pathname)
                let base_gs1 = window.location.pathname.replace("/", "");
                //apiController.deposit(countryCode + phoneNumber, base_gs1, setDeposited, setShowCouponExistsAlert);
              }
              //setConfirmation(true);
            }}>

            <Text style={{ color: COLORS.white, fontWeight: 'bold', fontSize: 16 }} allowFontScaling={false}>
              {indicatorVisible ? "" : "Continue"}
            </Text>

            {indicatorVisible &&
              <View
                style={{
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  opacity: 1,
                }}>
                <SkypeIndicator
                  style={{
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignSelf: 'center',
                  }}
                  color={COLORS.white}
                />
              </View>
            }

          </TouchableOpacity>
          {/* <View style={styles.inputContainer}>
                <TextInput
                    placeholder="Email"
                    value={email}
                    onChangeText={text => setEmail(text)}
                    style={styles.input}
                />
                <TextInput
                    placeholder="Password"
                    value={password}
                    onChangeText={text => setPassword(text)} 
                    style={styles.input}
                    secureTextEntry
                />
            </View> */}

          {/* Setup your bndl™ Wallet and bndl™ your coupons */}
          <View
            style={{
              flexDirection: 'column',
              marginTop: SIZES.padding,
              //marginBottom: SIZES.height * 0.1,
              justifyContent: 'center',
            }}>
            <Text
              style={{
                color: COLORS.white, fontWeight: 'normal', fontSize: 16,
                textAlign: 'center',
              }}
              allowFontScaling={false}>
              <Text style={{ color: COLORS.white, fontSize: 16 }} allowFontScaling={false}>
                {"Setup your"}
              </Text>{' '}
              <Text
                style={{
                  color: COLORS.white, fontSize: 16
                }}
                allowFontScaling={false}>
                Gitsy™
              </Text>{' '}
              <Text
                style={{
                  color: COLORS.white, fontSize: 16
                }}
                allowFontScaling={false}>
                Wallet and
              </Text>
              {'\n'}
              <Text
                style={{
                  color: COLORS.white, fontSize: 16
                }}
                allowFontScaling={false}>
                bundle
              </Text>{' '}
              <Text
                style={{
                  color: COLORS.white, fontSize: 16
                }}
                allowFontScaling={false}>
                your coupons
              </Text>
            </Text>
          </View>
          <View>
            {/* Terms of Service and Privacy Policy */}
            <View
              style={{
                flexDirection: 'column',
                marginTop: SIZES.padding * 3,
                justifyContent: 'center',
              }}>
              <Text
                style={{
                  color: COLORS.white,
                  textAlign: 'center',
                }}
                allowFontScaling={false}>
                By continuing, you agree to our
              </Text>

              <Text
                style={{
                  color: COLORS.white,
                  textAlign: 'center',
                }}
                allowFontScaling={false}>
                <Text
                  style={{
                    color: COLORS.white,
                    textDecorationLine: 'underline',
                  }}
                  allowFontScaling={false}
                  onPress={() => Linking.openURL(constants.APP_LINKS.TERMS_OF_USE)}>
                  Terms of Use
                </Text>{' '}
                and{' '}
                <Text
                  style={{
                    color: COLORS.white,
                    textDecorationLine: 'underline',
                  }}
                  allowFontScaling={false}
                  onPress={() => Linking.openURL(constants.APP_LINKS.PRIVACY_POLICY)}>
                  Privacy Policy
                </Text>
              </Text>
            </View>
            {/* Copyright */}
            <View
              style={{
                flexDirection: 'column',
                marginTop: SIZES.padding,
                justifyContent: 'center',
              }}>
              <Text
                style={{
                  color: COLORS.white,
                  textAlign: 'center',
                }}
                allowFontScaling={false}>
                © Spectiv Labs Inc.
              </Text>
            </View>
          </View>

          <Modal animationType="fade" transparent={true} visible={showErrorModal} onRequestClose={() => setShowErrorModal(false)}>
            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                backgroundColor: COLORS.transparentBlack5,
              }}>
              {/* Transparent Background */}
              <TouchableWithoutFeedback onPress={() => { setShowErrorModal(false) }}>
                <View
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
              </TouchableWithoutFeedback>
              <View
                style={{
                  left: 0,
                  // bottom: modalY,
                  flexDirection: 'column',
                  width: '100%',
                  height: 200,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 41,
                  paddingBottom: 44,
                  // padding: SIZES.padding,
                  borderRadius: 20,
                  backgroundColor: COLORS.white,
                  shadowColor: COLORS.black,
                  shadowOffset: { width: 0, height: 1 },
                  shadowOpacity: 0.2,
                  shadowRadius: 30,
                  elevation: 3,
                }}>
                <View
                  style={{
                    width: SIZES.width,
                    padding: SIZES.radius,
                    borderRadius: SIZES.radius,
                    backgroundColor: COLORS.white,
                    height: SIZES.height / 4,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <View>
                    <Text style={{ fontSize: 20, color: COLORS.darkTextColor, fontFamily: 'Ubuntu-Bold', marginBottom: 10, textAlign: 'center' }}
                      allowFontScaling={false}>
                      {errorTitle}
                    </Text>
                    <Text style={{ fontSize: 16, lineHeight: 24, color: COLORS.darkTextColor, fontFamily: 'Ubuntu-Regular', textAlign: 'center' }}
                      allowFontScaling={false}>
                      {errorMessage}
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20, width: '80%' }}>
                    <TouchableOpacity
                      style={{
                        alignItems: 'center',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        backgroundColor: COLORS.primary,
                        alignItems: 'center',
                        height: 50,
                        width: 120,
                        borderRadius: 25,
                        borderWidth: SIZES.borderWidth2,
                        borderColor: COLORS.white,
                        shadowColor: COLORS.gray5,
                        backgroundColor: COLORS.primary,
                        color: COLORS.white,
                        fontSize: 18,
                      }}
                      onPress={() => {
                        setShowErrorModal(false);
                      }}
                    >
                      <Text style={{ color: COLORS.white }} allowFontScaling={false}>
                        Close
                      </Text>
                    </TouchableOpacity>


                  </View>
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      alignContent: 'center',
                      marginTop: 10,
                      alignSelf: 'center',
                    }}
                    onPress={() => navigation.goBack()}>
                    {/* <Text
            style={{
              fontFamily: 'Ubuntu-Regular',
              fontSize: 16,
              lineHeight: 22,
              color: COLORS.gray,
              textDecorationLine: 'underline',

            }}
            allowFontScaling={false}
            numberOfLines={2}
            ellipsizeMode="tail">
            {'Try another phone number'}
          </Text> */}
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </ImageBackground>
      </KeyboardAvoidingView>
    )
}

export default Signin

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.appBackground
    },
    inputContainer: {
        width: '80%'
    },
    phoneInput: {
        flexDirection: 'row',
        height: 50,
        paddingHorizontal: SIZES.radius,
        borderRadius: 25,
        borderWidth: 1,
        borderColor: COLORS.lightGray3,
        backgroundColor: COLORS.white,
        color: COLORS.primaryTextColor,
        textAlign: 'center'
    },
    input: {
        backgroundColor: 'white',
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 10,
        marginTop: 5,
    },
    buttonContainer: {
        width: '60%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 40,
    },
    button: {
        backgroundColor: '#0782F9',
        width: '100%',
        padding: 15,
        borderRadius: 10,
        alignItems: 'center',
    },
    buttonOutline: {
        backgroundColor: 'white',
        marginTop: 5,
        borderColor: '#0782F9',
        borderWidth: 2,
    },
    buttonText: {
        color: 'white',
        fontWeight: '700',
        fontSize: 16,
    },
    buttonOutlineText: {
        color: '#0782F9',
        fontWeight: '700',
        fontSize: 16,
    },
});