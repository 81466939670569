const loader = require('../assets/splash/loading.json')
const qrLoader = require('../assets/loader/QRcode.json')
const barCodeLoader = require('../assets/loader/barcode.json')
const swipeRight = require('../assets/loader/swipe_right.json')
const swipeLeft = require('../assets/loader/swipe_left.json')

export default {
  loader,
  qrLoader,
  barCodeLoader,
  swipeRight,
  swipeLeft
};