import { KeyboardAvoidingView, StyleSheet, Text, TextInput, TouchableOpacity, View, Image, Modal, TouchableWithoutFeedback, Linking, ImageBackground } from 'react-native'
import React from 'react'
import { COLORS, FONTS, images, SIZES, ANIMATIONS, dummyData, icons, theme, constants } from '../constants'
import { useNavigation } from '@react-navigation/native'
import { SkypeIndicator } from 'react-native-indicators'
/* import { FormInput } from '../components'
import { utils } from '../utils'  */
/* import { TextBorderButton } from '../components'*/
import { apiController, utils } from '../utils'

const Otp = ({ route }) => {

    const [confirm, setConfirm] = React.useState(null);
    const [timer, setTimer] = React.useState(30);
    const [otp, setOtp] = React.useState('');
    const [OtpVerified, setOtpVerified] = React.useState(false);
    const [otpError, setOtpError] = React.useState(constants.NO_ERROR_MESSAGE);
    //for resend otp
    const [vc, setVc] = React.useState('');
    const [firebaseToken, setFirebaseToken] = React.useState(null);
    const [user, setUser] = React.useState(null);
    const [infoUpdated, setInfoUpdated] = React.useState(false);

    const [showPass, setShowPass] = React.useState(false);
    const [saveMe, setSaveMe] = React.useState(false);
    const [currentOtp, setCurrentOtp] = React.useState('');
    // invalid Otp View
    const [invalidOtpModal, setInvalidOtpModal] = React.useState(false);

    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [countryData, setCountryData] = React.useState([]);
    const [selectedCountry, setSelectedCountry] = React.useState('');
    const [countryCode, setCountryCode] = React.useState('+91');
    const [phoneNumberError, setPhoneNumberError] = React.useState(
        constants.NO_ERROR_MESSAGE,
    );
    const [indicatorVisible, setIndicatorVisible] = React.useState(false);
    const [confirmation, setConfirmation] = React.useState(false);
    const [txnId, setTxnId] = React.useState('');
    // Error Modal View
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorTitle, setErrorTitle] = React.useState('');

    const navigation = useNavigation()
    React.useEffect(() => {
        let { phoneNumber } = route.params;
        setPhoneNumber(phoneNumber);
        let { confirm } = route.params;
        setConfirm(confirm);
        let { txnId } = route.params;
        setTxnId(txnId);


        let interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer > 0) {
                    return prevTimer - 1;
                } else {
                    return prevTimer;
                }
            });
        }, 1000);

        // return a function for react to clean up
        //cleanup the interval
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        //console.log('OTP_VERIFIED:', OtpVerified);
        //console.log('OTP_VC:', vc);
        if (OtpVerified == true && vc != '') {
            utils.storeStringData(constants.ASYNC_STORAGE_DATA.PROVIDER_VC, vc,);
            utils.storeStringData(constants.ASYNC_STORAGE_DATA.PHONE_NUMBER, phoneNumber,);
            /* navigation.navigate('Home', {
                phoneNumber: phoneNumber,
                vc: vc,
            }); */
            apiController.registerUserWithPhone(phoneNumber, setFirebaseToken, setErrorMessage, setErrorTitle, setShowErrorModal);
        }
    }, [OtpVerified, vc]);
    React.useEffect(() => {
        //apiController.getCountryData(setCountryData, setCountryCode, setSelectedCountry)
        ////console.log('AUTH_TXN_ID = ', txnId);
        if (confirmation) {
            setConfirmation(false);
            navigation.navigate('Otp', {
                phoneNumber: countryCode + phoneNumber,
                txnId: txnId,
                confirm: confirmation,
            });
        }
    }, [confirmation]);

    React.useEffect(() => {
        if (firebaseToken != null) {
          apiController.initiateAuth(phoneNumber, firebaseToken, setUser, setErrorMessage, setErrorTitle, setShowErrorModal);
    
        }
      }, [firebaseToken]);
    
    React.useEffect(() => {
    if (user != null) {
        //apiController.updatePushToken(null, setInfoUpdated);
        setIndicatorVisible(false);
        navigation.navigate('Home', {
            phoneNumber: phoneNumber,
            vc: vc,
        });
    }
    }, [user]);
    React.useEffect(() => {
    if (user != null) {
        navigation.navigate('Home', {
            phoneNumber: phoneNumber,
            vc: vc,
        });
    }
    }, [infoUpdated]);

    function isEnableSignIn() {
        return otp != '' && otpError == constants.NO_ERROR_MESSAGE && otp.length == 6;
    }
    return (
        <KeyboardAvoidingView
            style={styles.container}>
            <ImageBackground
                source={images.frame}
                resizeMode="cover"
                style={{
                    flex: 1, width: '100%', height: '100%', position: 'absolute',
                    alignItems: 'center', justifyContent: 'center',
            }}>
            <Image
                source={images.logo_02}
                resizeMode="contain"
                style={{
                    height: 100,
                    width: 120,
                }}
            />
            <Text
                style={{
                    textAlign: 'center',
                    color: COLORS.white,
                    marginBottom: 5,
                    fontSize: 18,
                }}
                allowFontScaling={false}>
                One-time passcode sent to
            </Text>
            <Text
                style={{
                    textAlign: 'center',
                    color: COLORS.white,
                    marginBottom: 20,
                    fontSize: 18,
                }}
                allowFontScaling={false}>
                {phoneNumber}
            </Text>
            <TouchableOpacity
                style={{
                    justifyContent: 'center',
                    alignContent: 'center',
                    marginTop: -10,
                    marginBottom: 10,
                    alignSelf: 'center',
                }}
                onPress={() => navigation.goBack()}>
                <Text
                    style={{
                        textAlign: 'center',
                        color: COLORS.white,
                        marginBottom: 20,
                        fontSize: 14,
                        textDecorationLine: 'underline',
                    }}
                    allowFontScaling={false}
                    numberOfLines={2}
                    ellipsizeMode="tail">
                    {'Edit Phone Number'}
                </Text>
            </TouchableOpacity>
            <View
                style={{
                    flexDirection: 'row',
                    height: 84,
                    paddingHorizontal: 10,
                    borderColor: COLORS.transparentWhite1,
                    borderWidth: 9,
                    borderRadius: 42,
                    backgroundColor: COLORS.transparent,
                    alignItems: 'center',
                }}>

                <TextInput
                    placeholder="One-time passcode"
                    /* value={email}
                    onChangeText={text => setEmail(text)} */
                    style={styles.phoneInput}
                    placeholderTextColor={COLORS.gray2}
                    secureTextEntry={false}
                    keyboardType={"phone-pad"}
                    autoCapitalize={'none'}
                    maxLength={6}
                    
                    onChangeText={text => {utils.validateOtp(text, setOtpError);
                        setOtp(text);}}
                />
            </View>
            {/* Sign In with Phone Number */}
            <TouchableOpacity
                style={{
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    backgroundColor: COLORS.primary,
                    borderWidth: 4,
                    height: 50,
                    width: 150,
                    alignItems: 'center',
                    marginTop: SIZES.padding,
                    borderRadius: 50,
                    borderWidth: SIZES.borderWidth3,
                    borderColor: COLORS.white,
                    opacity: isEnableSignIn() ? 1 : 0.5,
                }}
                disabled={isEnableSignIn() ? false : true}
                onPress={() => {
                    setIndicatorVisible(true);
                    apiController.verifyVcAuth(txnId, otp, confirm, setOtpVerified, setVc, setIndicatorVisible, setInvalidOtpModal, setShowErrorModal, setErrorMessage, setErrorTitle);
                }}>

                <Text style={{ color: COLORS.white, fontWeight: 'bold', fontSize: 16 }} allowFontScaling={false}>
                    {indicatorVisible ? "" : "Get Going"}
                </Text>

                {indicatorVisible &&
                    <View
                        style={{
                            position: 'absolute',
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            opacity: 1,
                        }}>
                        <SkypeIndicator
                            style={{
                                position: 'absolute',
                                alignItems: 'center',
                                justifyContent: 'center',
                                alignSelf: 'center',
                            }}
                            color={COLORS.white}
                        />
                    </View>
                }

            </TouchableOpacity>
            {/* <View style={styles.inputContainer}>
                <TextInput
                    placeholder="Email"
                    value={email}
                    onChangeText={text => setEmail(text)}
                    style={styles.input}
                />
                <TextInput
                    placeholder="Password"
                    value={password}
                    onChangeText={text => setPassword(text)} 
                    style={styles.input}
                    secureTextEntry
                />
            </View> */}
            {/* Setup your bndl™ Wallet and bndl™ your coupons */}
            <View
                style={{
                    flexDirection: 'column',
                    marginTop: SIZES.padding,
                    //marginBottom: SIZES.height * 0.1,
                    justifyContent: 'center',
                }}>
                <Text
                    style={{
                        color: COLORS.white, fontWeight: 'normal', fontSize: 16,
                        textAlign: 'center',
                    }}
                    allowFontScaling={false}>
                    <Text style={{ color: COLORS.white, fontSize: 16 }} allowFontScaling={false}>
                        {"Setup your"}
                    </Text>{' '}
                    <Text
                        style={{
                            color: COLORS.white, fontSize: 16
                        }}
                        allowFontScaling={false}>
                        Gitsy™
                    </Text>{' '}
                    <Text
                        style={{
                            color: COLORS.white, fontSize: 16
                        }}
                        allowFontScaling={false}>
                        Wallet and
                    </Text>
                    {'\n'}
                    <Text
                        style={{
                            color: COLORS.white, fontSize: 16
                        }}
                        allowFontScaling={false}>
                        bundle
                    </Text>{' '}
                    <Text
                        style={{
                            color: COLORS.white, fontSize: 16
                        }}
                        allowFontScaling={false}>
                        your coupons
                    </Text>
                </Text>
            </View>
            <View>
                {/* Terms of Service and Privacy Policy */}
                <View
                    style={{
                        flexDirection: 'column',
                        marginTop: SIZES.padding * 3,
                        justifyContent: 'center',
                    }}>
                    <Text
                        style={{
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                        allowFontScaling={false}>
                        By continuing, you agree to our
                    </Text>

                    <Text
                        style={{
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                        allowFontScaling={false}>
                        <Text
                            style={{
                                color: COLORS.white,
                                textDecorationLine: 'underline',
                            }}
                            allowFontScaling={false}
                            onPress={() => Linking.openURL(constants.APP_LINKS.TERMS_OF_USE)}>
                            Terms of Use
                        </Text>{' '}
                        and{' '}
                        <Text
                            style={{
                                color: COLORS.white,
                                textDecorationLine: 'underline',
                            }}
                            allowFontScaling={false}
                            onPress={() => Linking.openURL(constants.APP_LINKS.PRIVACY_POLICY)}>
                            Privacy Policy
                        </Text>
                    </Text>
                </View>
                {/* Copyright */}
                <View
                    style={{
                        flexDirection: 'column',
                        marginTop: SIZES.padding,
                        justifyContent: 'center',
                    }}>
                    <Text
                        style={{
                            color: COLORS.white,
                            textAlign: 'center',
                        }}
                        allowFontScaling={false}>
                        © Spectiv Labs Inc.
                    </Text>
                </View>
            </View>
            <Modal animationType="fade" transparent={true} visible={showErrorModal} onRequestClose={() => setShowErrorModal(false)}>
                <View
                    style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                        backgroundColor: COLORS.transparentBlack5,
                    }}>
                    {/* Transparent Background */}
                    <TouchableWithoutFeedback onPress={() => { setShowErrorModal(false) }}>
                        <View
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                        />
                    </TouchableWithoutFeedback>
                    <View
                        style={{
                            left: 0,
                            // bottom: modalY,
                            flexDirection: 'column',
                            width: '100%',
                            height: 200,
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: 41,
                            paddingBottom: 44,
                            // padding: SIZES.padding,
                            borderRadius: 20,
                            backgroundColor: COLORS.white,
                            shadowColor: COLORS.black,
                            shadowOffset: { width: 0, height: 1 },
                            shadowOpacity: 0.2,
                            shadowRadius: 30,
                            elevation: 3,
                        }}>
                        <View
                            style={{
                                width: SIZES.width,
                                padding: SIZES.radius,
                                borderRadius: SIZES.radius,
                                backgroundColor: COLORS.white,
                                height: SIZES.height / 4,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <View>
                                <Text style={{ fontSize: 20, color: COLORS.darkTextColor, fontFamily: 'Ubuntu-Bold', marginBottom: 10, textAlign: 'center' }}
                                    allowFontScaling={false}>
                                    {errorTitle}
                                </Text>
                                <Text style={{ fontSize: 16, lineHeight: 24, color: COLORS.darkTextColor, fontFamily: 'Ubuntu-Regular', textAlign: 'center' }}
                                    allowFontScaling={false}>
                                    {errorMessage}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 20, width: '80%' }}>
                                <TouchableOpacity
                                    style={{
                                        alignItems: 'center',
                                        alignSelf: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: COLORS.primary,
                                        alignItems: 'center',
                                        height: 50,
                                        width: 120,
                                        borderRadius: 25,
                                        borderWidth: SIZES.borderWidth2,
                                        borderColor: COLORS.white,
                                        shadowColor: COLORS.gray5,
                                        backgroundColor: COLORS.primary,
                                        color: COLORS.white,
                                        fontSize: 18,
                                    }}
                                    onPress={() => {
                                        setShowErrorModal(false);
                                    }}
                                >
                                    <Text style={{ color: COLORS.white }} allowFontScaling={false}>
                                        Close
                                    </Text>
                                </TouchableOpacity>


                            </View>
                            <TouchableOpacity
                                style={{
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    marginTop: 10,
                                    alignSelf: 'center',
                                }}
                                onPress={() => navigation.goBack()}>
                                {/* <Text
            style={{
              fontFamily: 'Ubuntu-Regular',
              fontSize: 16,
              lineHeight: 22,
              color: COLORS.gray,
              textDecorationLine: 'underline',

            }}
            allowFontScaling={false}
            numberOfLines={2}
            ellipsizeMode="tail">
            {'Try another phone number'}
          </Text> */}
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
            </ImageBackground>
        </KeyboardAvoidingView>
    )
}

export default Otp

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.appBackground
    },
    inputContainer: {
        width: '80%'
    },
    phoneInput: {
        flexDirection: 'row',
        height: 50,
        paddingHorizontal: SIZES.radius,
        borderRadius: 25,
        borderWidth: 1,
        borderColor: COLORS.lightGray3,
        backgroundColor: COLORS.white,
        textAlign:'center',
        color: COLORS.primaryTextColor,
    },
    input: {
        backgroundColor: 'white',
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 10,
        marginTop: 5,
    },
    buttonContainer: {
        width: '60%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 40,
    },
    button: {
        backgroundColor: '#0782F9',
        width: '100%',
        padding: 15,
        borderRadius: 10,
        alignItems: 'center',
    },
    buttonOutline: {
        backgroundColor: 'white',
        marginTop: 5,
        borderColor: '#0782F9',
        borderWidth: 2,
    },
    buttonText: {
        color: 'white',
        fontWeight: '700',
        fontSize: 16,
    },
    buttonOutlineText: {
        color: '#0782F9',
        fontWeight: '700',
        fontSize: 16,
    },
});