const myProfile = {
  name: 'Russel Mack',
  profile_image: require('../assets/icons/my_profile.png'),
  address: '(+1) 650 623 8953',
};

const categories = [
  {
    id: 1,
    name: 'All Coupons (9)',
  },
  {
    id: 2,
    name: 'Grocery (4)',
  },
  {
    id: 3,
    name: 'Cosmetics (2)',
  },
  {
    id: 4,
    name: 'My Sunday Trip (0)',
  },
];

const sizes = [
  {
    id: 1,
    label: '12"',
  },
  {
    id: 2,
    label: '14"',
  },
  {
    id: 3,
    label: '16"',
  },
  {
    id: 4,
    label: '18"',
  },
];

const coupon_barcodes = [
  {
    id: 1,
    title: 'Master Barcode',
    barcode: '8112111177774594029966311111',
    expiry: '00:18:12',
    total_coupons: '42',
    total_selected_coupons: '10',
    total_selected_value: '$14.20',
    description: 'You can redeem only 10 coupons at a time',
  },
  {
    id: 2,
    title: 'Barcode 2',
    barcode: '811211117777459402997459402996',
    expiry: '00:18:12',
    total_coupons: '23',
    total_selected_coupons: '10',
    total_selected_value: '$16.40',
    description: 'You can redeem only 10 coupons at a time',
  },
];

const coupons = [
  {
    description: 'Save 100 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c1',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://www.stockupmarket.com/wp-content/uploads/2020/07/Welchs_Fruit_Snacks_Mixed_Fruit_5_Ounce.png',
    terms: 'https://www.spectivlabs.com/privacypolicy',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c2',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://veggievital.co/wp-content/uploads/2021/04/MACADAMIA-COCO-Y-CHOCOLATE-1000X1000.png',
    terms: 'These Terms and Conditions of Use (the "Terms of Use") apply to the Apple web site located at www.apple.com, and all associated sites linked to www.apple.com by Apple, its subsidiaries and affiliates, including Apple sites around the world (collectively, the "Site"). The Site is the property of Apple Inc. ("Apple") and its licensors. BY USING THE SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT AGREE, DO NOT USE THE SITE. Apple reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Site following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms of Use, Apple grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the Site.',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c3',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://veggievital.co/wp-content/uploads/2021/04/MACADAMIA-COCO-Y-CHOCOLATE-1000X1000.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c4',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Procter_%26_Gamble_logo.svg/1200px-Procter_%26_Gamble_logo.svg.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c5',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://w7.pngwing.com/pngs/152/701/png-transparent-bentonville-walmart-supercenter-wal-mart-3722-supercenter-retail-business-blue-text-retail.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c6',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://www.vhv.rs/dpng/d/52-523707_grocery-shopping-icon-png-transparent-png.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c7',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://www.vhv.rs/dpng/d/52-523707_grocery-shopping-icon-png-transparent-png.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c8',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://www.vhv.rs/dpng/d/52-523707_grocery-shopping-icon-png-transparent-png.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c9',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://www.vhv.rs/dpng/d/52-523707_grocery-shopping-icon-png-transparent-png.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c10',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://www.vhv.rs/dpng/d/52-523707_grocery-shopping-icon-png-transparent-png.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c11',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://www.vhv.rs/dpng/d/52-523707_grocery-shopping-icon-png-transparent-png.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
  {
    description: 'Save 1 Desc',
    brand: "Izzy's Ice Cream",
    coupon_id: '618fd060684ffb53bf9b31c12',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    serialized_gs1: '811201151919110043248437766566',
    title: 'Save 1 Title',
    image_url:
      'https://www.vhv.rs/dpng/d/52-523707_grocery-shopping-icon-png-transparent-png.png',
    terms: '...',
    dollar_amount: 2,
    expires_in: '10 Days',
  },
];
const coupons_others = [
  {
    coupon_id: '618fd060684ffb53bf9b31c101',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    image_url:
      'https://i.pinimg.com/originals/d2/e2/0c/d2e20cb981074ed3f8fcc74a2103a2af.jpg',
    dollar_amount: 2,
    description:
      'Save $0.25 On any ONE (1) Welch’s Fruit Snacks (8oz or larger bag or 8ct or larger box)',
    expires_in: '10 Days',
    serialized_gs1: '811201151919110043248437766566',
  },
  {
    coupon_id: '618fd060684ffb53bf9b31c102',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    image_url:
      'https://m.media-amazon.com/images/I/61JfYrXcJ4L._AC_SX704_SY660_.jpg',
    dollar_amount: 2,
    description: 'SkinnyPop Popcorn',
    expires_in: '10 Days',
    serialized_gs1: '811201151919110043248437766566',
  },
  {
    coupon_id: '618fd060684ffb53bf9b31c103',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    image_url:
      'https://www.ubuy.co.in/productimg/?image=aHR0cHM6Ly9tLm1lZGlhLWFtYXpvbi5jb20vaW1hZ2VzL0kvODFoc0RWVkMyM0wuX0FDX1NMMTUwMF8uanBn.jpg',
    dollar_amount: 2,
    description: "Nature's Bounty®",
    expires_in: '10 Days',
    serialized_gs1: '811201151919110043248437766566',
  },
  {
    coupon_id: '618fd060684ffb53bf9b31c104',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    image_url:
      'https://m.media-amazon.com/images/I/61NykBGeSSL._AC_SL1165_.jpg',
    dollar_amount: 2,
    description: 'Jif® Squeeze Peanut Butter',
    expires_in: '10 Days',
    serialized_gs1: '811201151919110043248437766566',
  },
  {
    coupon_id: '618fd060684ffb53bf9b31c105',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    image_url:
      'https://www.yoplait.com/wp-content/uploads/2020/12/yoplait-original-strawberry-tub-460x460-1.png',
    dollar_amount: 2,
    description: 'Yoplait® Yogurt',
    expires_in: '10 Days',
    serialized_gs1: '811201151919110043248437766566',
  },
  {
    coupon_id: '618fd060684ffb53bf9b31c106',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    image_url:
      'https://images.ctfassets.net/3867whnzui9o/US-4ZuTBd1kVXNYsL5JnDyNWE/64fd394e5efc47097e6b6ba7aa231c8c/Activia-Hero_6PK.png?w=800&q=50',
    dollar_amount: 2,
    description: 'Activia®+ Probiotic Drink',
    expires_in: '10 Days',
    serialized_gs1: '811201151919110043248437766566',
  },
  {
    coupon_id: '618fd060684ffb53bf9b31c107',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    image_url:
      'https://www.dennisexpress.com/wp-content/uploads/2020/08/purell-sanitizer-bottles.jpg',
    dollar_amount: 2,
    description: 'PURELL® Hand Sanitizer',
    expires_in: '10 Days',
    serialized_gs1: '811201151919110043248437766566',
  },
  {
    coupon_id: '618fd060684ffb53bf9b31c108',
    valid_till: '2021-12-10',
    valid_till_human_readable: '10 December, 2021',
    image_url:
      'https://cdn.shopify.com/s/files/1/2382/0223/products/41226-1500912642_a9934f32-6e8e-4675-90e6-9688c6f0f833_1200x1200.png?v=1571614146',
    dollar_amount: 2,
    description: 'BLUE™ Dry Cat Food',
    expires_in: '10 Days',
    serialized_gs1: '811201151919110043248437766566',
  },
];
const shopping_passes = [
  {
    id: 1,
    type: 'PASS',
    logo_url:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Procter_%26_Gamble_logo.svg/1200px-Procter_%26_Gamble_logo.svg.png',
    total_coupons: '10',
    total_value: '$14.20',
    total_receipts: '',
  },
  {
    id: 2,
    type: 'IN STORE',
    logo_url:
      'https://www.freepnglogos.com/uploads/target-png/target-logo-png-transparent-svg-vector-bie-supply-35.png',
    total_coupons: '14',
    total_value: '$14.20',
    total_receipts: '',
  },
  {
    id: 3,
    type: 'slip',
    logo_url: '',
    total_coupons: '',
    total_value: '$14.20',
    total_receipts: '12',
  },
];
const receipts = [
  {
    id: 1,
    logo_url:
      'https://purepng.com/public/uploads/large/purepng.com-walmart-logologobrand-logoiconslogos-251519939045oqelv.png',
    receipt_value: '14.20',
    receipt_date: '1 Jan, 2022',
    receipt_address: '8771 Douglas Summit Port Sigrid, Colorado, 42248-8681',
  },
  {
    id: 2,
    logo_url:
      'https://www.freepnglogos.com/uploads/costco-png-logo/corrugated-shipping-crates-costco-png-logo-13.png',
    receipt_value: '14.20',
    receipt_date: '1 Jan, 2022',
    receipt_address: '8771 Douglas Summit Port Sigrid, Colorado, 42248-8681',
  },
  {
    id: 3,
    logo_url: 'https://cdn2.phillypaws.org/wp-content/uploads/target.png',
    receipt_value: '14.20',
    receipt_date: '1 Jan, 2022',
    receipt_address: '19664 Grady Port Lake Earl, Iowa, 52337',
  },
  {
    id: 4,
    logo_url:
      'https://purepng.com/public/uploads/large/purepng.com-walmart-logologobrand-logoiconslogos-251519939045oqelv.png',
    receipt_value: '14.20',
    receipt_date: '1 Jan, 2022',
    receipt_address: '23388 Koelpin Falls Kossbury, Louisiana, 82979-3211',
  },
  {
    id: 5,
    logo_url:
      'https://www.freepnglogos.com/uploads/costco-png-logo/corrugated-shipping-crates-costco-png-logo-13.png',
    receipt_value: '14.20',
    receipt_date: '1 Jan, 2022',
    receipt_address: '9661 Heller Mill Beahanmouth, Louisiana, 70054-4881',
  },
  {
    id: 6,
    logo_url: 'https://cdn2.phillypaws.org/wp-content/uploads/target.png',
    receipt_value: '14.20',
    receipt_date: '1 Jan, 2022',
    receipt_address: '116 Romaguera Rapids Michelestad, New Mexico, 33258-3816',
  },
];
const couponImages = [
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346287178_Natures-Path-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346287178_Natures-Path-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346287178_Natures-Path-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346293982_Orgain-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346293982_Orgain-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346293982_Orgain-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346293982_Orgain-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346327850_Violife-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346327850_Violife-WS22-Q2-product-image.png",

];
const couponImageUrls = [
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3Zl3N-xG4VyEX04h6Fy9FYdRK89CjoZzplaIlTmnPFQ&s",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346287178_Natures-Path-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346287178_Natures-Path-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346293982_Orgain-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346293982_Orgain-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346293982_Orgain-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346293982_Orgain-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346327850_Violife-WS22-Q2-product-image.png",
  "https://d1z9gfg28b1nwn.cloudfront.net/uploads/fcbf008a9128050fbf204780acd5bd3f/product/1649346327850_Violife-WS22-Q2-product-image.png",

];
export default {
  myProfile,
  categories,
  sizes,
  coupon_barcodes,
  coupons,
  coupons_others,
  shopping_passes,
  receipts,
  couponImages,
  couponImageUrls,
};
