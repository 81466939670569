const screens = {
  main_layout: 'MainLayout',
  myCoupons: 'My Coupons',
  shoppingPasses: 'Shopping Passes',
  myAccount: 'My Profile',
  home: 'Home',
  //search: "Search",
  favourite: 'Favourite',
  notification: 'Notification',
  my_wallet: ' My Coupons ',
};
const action_types = {
  action_none: 'ActionNone',
  action_bundle: 'ActionBundle',
  action_coupons_selected: 'ActionCouponsSelected',
  action_coupons_selected: 'Bundle_created',
};
const code_types = {
  qr_code: 'QR ',
  bar_code: 'Bar',
};

const bottom_tabs = [
  {
    id: 0,
    label: screens.myCoupons,
  },
  {
    id: 1,
    label: screens.shoppingPasses,
  },
  {
    id: 2,
    label: screens.myAccount,
  },
  {
    id: 3,
    label: screens.favourite,
  },
  {
    id: 4,
    label: screens.notification,
  },
];

const gender = [
  {
    id: 0,
    label: 'Male',
    value: 'Male',
  },
  {
    id: 1,
    label: 'Female',
    value: 'Female',
  },
];
const ageGroup = [
  {
    id: 0,
    label: '25 to 30',
    value: '25 to 30',
  },
  {
    id: 1,
    label: '31 to 35',
    value: '31 to 35',
  },
  {
    id: 2,
    label: '36 to 40',
    value: '36 to 40',
  },
  {
    id: 3,
    label: '41 to 45',
    value: '41 to 45',
  },
  {
    id: 4,
    label: '46 to 50',
    value: '46 to 50',
  },
  {
    id: 5,
    label: '50+',
    value: '50+',
  },
];

const state = [
  {
    id: 0,
    label: 'Sarawak',
    value: 'Sarawak',
  },
  {
    id: 1,
    label: 'Sabah',
    value: 'Sabah',
  },
  {
    id: 2,
    label: 'Johor',
    value: 'Johor',
  },
  {
    id: 3,
    label: 'Kedah',
    value: 'Kedah',
  },
  {
    id: 4,
    label: 'Kelantan',
    value: 'Kelantan',
  },
  {
    id: 5,
    label: 'Penang',
    value: 'Penang',
  },
];

const GOOGLE_MAP_API_KEY = '[Your Google API Key]';
const APP_ACCESS_KEY = 'f54b962cef64b63ba81a10e241a18bf6';
const APP_SECRET_KEY = 'e52f03c57131d6e95517d5f766334a9d';
const NO_ERROR_MESSAGE = ' ';
const API_BASE_URLS = {
  BASE_URL: 'https://try.gitsy.app/api',
};
const APP_LINKS = {
  TERMS_OF_USE: 'https://www.spectivlabs.com/termsandconditions',
  PRIVACY_POLICY: 'https://www.spectivlabs.com/privacypolicy',
  SUPPORT: 'https://www.spectivlabs.com/support',
  APP_VERSION: '{"version":"1.0.0","showAlert":false,"critical": false}',
};

const ASYNC_STORAGE_DATA = {
  APP_BASE_URL: 'APP_BASE_URL',
  TERMS_OF_USE: 'TERMS_OF_USE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  SUPPORT: 'SUPPORT',
  APP_VERSION: 'APP_VERSION',
  DO_NOT_SHOW_CONFIRM_PRINT: 'DO_NOT_SHOW_CONFIRM_PRINT',
  USER_ID: 'USER_ID',
  USER_SIGNED_IN: 'USER_SIGNED_IN',
  SKIPPED_SIGN_IN: 'SKIPPED_SIGN_IN',
  USER_SIGNED_IN_TCB: 'USER_SIGNED_IN_TCB',
  X_ACCESS_TOKEN: 'X_ACCESS_TOKEN',
  WALLET_KEY: 'X_ACCESS_TOKEN',
  TXN_ID: 'TXN_ID',
  PROVIDER_VC: 'PROVIDER_VC',
  PHONE_NUMBER: 'PHONE_NUMBER',
  CONSUMER_ID: 'CONSUMER_ID',
  FB_USER_ID: 'FB_USER_ID',
  COUPONS: 'COUPONS',
  COUPONS_OTHERS: 'COUPONS_OTHERS',
  FCM_TOKEN: 'FCM_TOKEN',
  NOTIFICATIONS: 'NOTIFICATIONS',
  BUNDLE_FEEDBACK_DONE: 'BUNDLE_FEEDBACK_DONE',
  HORIZONTAL_SCROLL_FEEDBACK_DONE: 'HORIZONTAL_SCROLL_FEEDBACK_DONE',
  SURVEY_STATUS: 'SURVEY_STATUS',
  DEAFULT_CODE: 'DEAFULT_CODE',
};

const API_END_POINTS = {
  GET_X_ACCESS_TOKEN: '/consumer/access_token',
  WALLETS: '/consumer/wallets',
  INITIATE_AUTH: '/consumer/initiate_auth',
  INITIATE_AUTH_INTERNAL: '/consumer/initiate_tcb_auth',
  VERIFY_AUTH: '/consumer/verify_auth',
  REGISTER_USER: '/consumer/register',
  GET_ALL_VALID_COUPONS: '/temp/coupons',
  GET_BUNDLE_ID_BARCODE: '/temp/bundle',
  FETCH_CODE_FOR_COUPON: '/temp/fetch_code',
  CREATE_BUNDLE: '/consumer/create_bundle',
  FETCH_BUNDLES: '/consumer/fetch_bundles',
  UPDATE_BUNDLE: '/consumer/update_bundle',
  UPDATE_PROFILE: '/consumer/update_profile',
  UPDATE_PUSH_TOKEN: '/consumer/update_push_token',
  SAVE_COUPONS: '/consumer/save_coupons',
  FETCH_NOTIFICATIONS: '/consumer/fetch_notifications',
  FETCH_RECEIPTS: '/consumer/fetch_receipts',
  GET_PROFILE: '/consumer/get_profile',
};

export default {
  screens,
  action_types,
  code_types,
  // coupon_barcodes,
  // coupons,
  // coupons_others,
  bottom_tabs,
  gender,
  ageGroup,
  state,
  GOOGLE_MAP_API_KEY,
  APP_ACCESS_KEY,
  APP_SECRET_KEY,
  NO_ERROR_MESSAGE,
  API_BASE_URLS,
  API_END_POINTS,
  ASYNC_STORAGE_DATA,
  APP_LINKS
};
