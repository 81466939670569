import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import {NavigationContainer} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import Signin from './screens/Signin';
import Home from './screens/Home';
import Otp from './screens/Otp';
import { initializeApp } from "firebase/app";
import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { COLORS, FONTS, images, SIZES, ANIMATIONS, dummyData, icons, theme } from './constants'
import { constants } from './constants';
import base64 from 'react-native-base64'
import { apiController } from './utils';



const Stack = createNativeStackNavigator();
const firebaseConfig = {
  apiKey: "AIzaSyBxqh1HYUKZfj9FPOxKrrImxnMpvuCReY8",
  authDomain: "bndl-prod.firebaseapp.com",
  projectId: "bndl-prod",
  storageBucket: "bndl-prod.appspot.com",
  messagingSenderId: "371711267444",
  appId: "1:371711267444:web:6ba3b349691a4f6c803721",
  measurementId: "G-6QJT08FV6W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default function App() {

  const [didUser, setDidUser] = React.useState(null);
  const [vc, setVc] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const getDidUser = async () => {
    const vc = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PROVIDER_VC);
    const phone_number = await AsyncStorage.getItem(constants.ASYNC_STORAGE_DATA.PHONE_NUMBER);
    let did = null;
    try {
      did = JSON.parse(base64.decode(vc)).credentialSubject[0].did_user;
    } catch (error) {
      //console.log("DID_USER_EXCEPTION", error);
    }
    
    setDidUser(did);
    setVc(vc);
    setPhoneNumber(phone_number);
    //console.log("DID_USER", did);
    //console.log("VC", vc);
    //console.log("PHONE_NUMBER", phone_number);
    
    /* Check the url and deposit coupon */
    
    //console.log("HREF: ",window.location.href); // Outputs: 'https://web.gitsy.app/{base_gs1}'
    //console.log("PROTOCOL: ",window.location.protocol);// Outputs: 'https:'
    //console.log("HOSTNAME: ",window.location.hostname);    // Outputs: 'timmousk.com'

    // Outputs: '/blog/react-get-current-url/'
    //console.log("PATHNAME: ",window.location.pathname)
    /* 
    if (phone_number != null && window.location.pathname != '/' && window.location.pathname.includes("8112")) {
      console.log("PATHNAME_TRUE: ",window.location.pathname)
      let base_gs1 = window.location.pathname.replace("/", "");
      apiController.deposit(phone_number, base_gs1);
    }
     */
  }

  React.useEffect(() => {
    getDidUser();
  }, []);
  return (
    <NavigationContainer>
      {vc ==null &&
      <Stack.Navigator>
        <Stack.Screen options={{ headerShown: false }} name="Signin" component={Signin} />
        <Stack.Screen options={{ headerShown: false }} name="Otp" component={Otp} />
        <Stack.Screen options={{ headerShown: false }} name="Home" component={Home} />
      </Stack.Navigator>
      }
      {vc !=null &&
      <Stack.Navigator>
        <Stack.Screen options={{ headerShown: false }} name="Home" component={Home} />
        <Stack.Screen options={{ headerShown: false }} name="Signin" component={Signin} />
        <Stack.Screen options={{ headerShown: false }} name="Otp" component={Otp} />
      </Stack.Navigator>
      }
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:COLORS.appBackground
  },
});
