const logo_01 = require('../assets/images/logo_01.png');
const logo_02 = require('../assets/images/logo_02.png');
const logo_03 = require('../assets/images/logo_03.png');
const gradient_01 = require('../assets/images/gradient_01.png');
const coupon = require('../assets/images/coupon.png');
const coupon_cut = require('../assets/images/coupon_cut.png');
const notch = require('../assets/images/notch.png');
const wave = require('../assets/images/wave.png');
const slipsave_logo = require('../assets/images/slipsave_logo.png');
const tab_divider = require('../assets/images/tab_divider.png');
const horizontal_dashes = require('../assets/images/horizontal_dashes.png');
const blue_tick_circle = require('../assets/images/blue_tick_circle.png');
const qr_error = require('../assets/images/qrerror.png');
const frame = require('../assets/images/frame.svg');

export default {
  logo_01,
  logo_02,
  logo_03,
  gradient_01,
  coupon,
  coupon_cut,
  notch,
  wave,
  slipsave_logo,
  tab_divider,
  horizontal_dashes,
  blue_tick_circle,
  qr_error,
  frame
};
