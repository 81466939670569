import {Dimensions} from 'react-native';
const {width, height} = Dimensions.get('window');

export const COLORS = {
  primary: '#2389e5', //orange
  transparentPrimary: 'rgba(245, 139, 0, 0.4)',
  transparentPrimary9: 'rgba(255, 238, 233, 0.9)',
  orange: '#FF9300',
  lightOrange: '#FDB924',
  lightOrange2: '#F8BA70',
  lightOrange3: '#FFD9AD',
  green: '#27AE60',
  red: '#FF1717',
  red2: '#FF6C44',
  blue: '#0658b2',
  darkBlue: '#111A2C',
  darkGray: '#525C67',
  darkGray2: '#757D85',
  gray: '#898B9A',
  gray2: '#BBBDC1',
  gray3: '#CFD0D7',
  gray4: '#53555F',
  gray5: '#30363A',
  gray6: '#A3A4A3',
  gray7: '#8C8C8C',
  gray8: '#191f21',
  lightGray1: '#DDDDDD',
  lightGray2: '#F5F5F8',
  lightGray3: '#E4E5E6',
  white2: '#FBFBFB',
  white: '#FFFFFF',
  black: '#000000',

  primaryTextColor: '#53555F',
  primarybodyColor: '#44565E',
  darkTextColor: '#191f21',

  transparent: 'transparent',
  transparentWhite1: 'rgba(255, 255, 255, 0.1)',
  transparentBlack1: 'rgba(0, 0, 0, 0.1)',
  transparentBlack7: 'rgba(0, 0, 0, 0.8)',
  transparentBlack5: 'rgba(0, 0, 0, 0.5)',
  appBackground: '#2389e5',
};
export const SIZES = {
  // global sizes
  base: 8,
  font: 14,
  radius: 12,
  padding: 24,
  contentViewTopPadding: 100,
  mainPadding: 18,
  borderWidth1: 1,
  borderWidth2: 2,
  borderWidth3: 3,
  borderWidth4: 4,
  borderWidth5: 5,
  borderWidth6: 6,

  // font sizes
  largeTitle: 40,
  largeTitle2: 36,
  h: 32,
  h1: 30,
  h1_1: 26,
  h1_2: 24,
  h2: 22,
  h2_1: 20,
  h3: 16,
  h4: 14,
  h5: 12,
  body1: 30,
  body2: 22,
  body3: 18,
  body4: 14,
  body5: 12,
  body6: 10,

  label1: 20,

  // app dimensions
  width,
  height,
};
export const ANIMATIONS = {
  // global animation values
  tabBarAnimationDuration: 250,
  tabBarTransitionSizeMax: 1.2,
  tabBarTransitionSizeMin: 1,
};
export const FONTS = {
  largeTitle: {fontFamily: 'Poppins-Black', fontSize: SIZES.largeTitle},
  largeTitle2: {fontFamily: 'Poppins-SemiBold', fontSize: SIZES.largeTitle2},
  h: {fontFamily: 'Poppins-Light', fontSize: SIZES.h},
  h1: {fontFamily: 'Poppins-Bold', fontSize: SIZES.h1, lineHeight: 36},
  h1_1: {fontFamily: 'Poppins-SemiBold', fontSize: SIZES.h1_1, lineHeight: 36},
  h1_2: {fontFamily: 'Poppins-SemiBold', fontSize: SIZES.h1_2, lineHeight: 34},
  h2: {fontFamily: 'Poppins-Bold', fontSize: SIZES.h2, lineHeight: 30},
  h3: {fontFamily: 'Poppins-SemiBold', fontSize: SIZES.h3, lineHeight: 22},
  h3i: {fontFamily: 'Poppins-Italic', fontSize: SIZES.h3, lineHeight: 22},
  h4: {fontFamily: 'Poppins-SemiBold', fontSize: SIZES.h4, lineHeight: 22},
  h5: {fontFamily: 'Poppins-SemiBold', fontSize: SIZES.h5, lineHeight: 22},
  h6: {fontFamily: 'Poppins-Medium', fontSize: SIZES.h3, lineHeight: 22},
  h7: {fontFamily: 'Poppins-Semibold', fontSize: SIZES.h2_1, lineHeight: 22},

  body1: {fontFamily: 'Poppins-Regular', fontSize: SIZES.body1, lineHeight: 36},
  body2: {fontFamily: 'Poppins-Regular', fontSize: SIZES.body2, lineHeight: 30},
  body3: {fontFamily: 'Ubuntu-Regular', fontSize: SIZES.body3, lineHeight: 22},
  body4: {
    fontFamily: 'WorkSans-Regular',
    fontSize: SIZES.body4,
    lineHeight: 22,
  },
  body5: {fontFamily: 'Poppins-Regular', fontSize: SIZES.body5, lineHeight: 22},
  body6: {
    fontFamily: 'Poppins-ExtraLight',
    fontSize: SIZES.body5,
    lineHeight: 22,
  },
  body7: {fontFamily: 'Poppins-Medium', fontSize: SIZES.body4, lineHeight: 22},
  body8: {fontFamily: 'Poppins-Regular', fontSize: SIZES.body6, lineHeight: 22},
  body9: {fontFamily: 'Poppins-Medium', fontSize: SIZES.body6, lineHeight: 22},
  body10: {fontFamily: 'Poppins-Medium', fontSize: SIZES.body4, lineHeight: 18},
  receipt: {fontFamily: 'Courier', fontSize: SIZES.body3, lineHeight: 18},

  buttonLabel1: {
    fontFamily: 'Ubuntu-Medium',
    fontSize: 20,
  },
  tabTitle: {fontFamily: 'WorkSans-Medium', fontSize: SIZES.h3, lineHeight: 22},
  screenTitle: {
    fontFamily: 'Ubuntu-Bold',
    fontSize: SIZES.body3,
    lineHeight: 22,
  },
};

const appTheme = {COLORS, SIZES, FONTS, ANIMATIONS};

export default appTheme;
