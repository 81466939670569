import {constants} from '../constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import messaging from '@react-native-firebase/messaging';
import DeviceInfo from 'react-native-device-info';

function isValidEmail(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

function validateEmail(value, setEmailError) {
  if (value == '') {
    setEmailError(constants.NO_ERROR_MESSAGE);
  } else if (isValidEmail(value)) {
    setEmailError(constants.NO_ERROR_MESSAGE);
  } else {
    setEmailError('Invalid Email');
  }
}

function isValidPhoneNumber(value) {
  const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return re.test(String(value).toLowerCase());
}

function validatePhoneNumber(value, setPhoneNumberError) {
  if (value == '') {
    setPhoneNumberError(constants.NO_ERROR_MESSAGE);
  } else if (isValidPhoneNumber(value)) {
    setPhoneNumberError(constants.NO_ERROR_MESSAGE);
  } else {
    setPhoneNumberError('Invalid Phone');
  }
}

function validatePassword(value, setPasswordError) {
  if (value.length < 9) {
    setPasswordError('Password must be 9 characters');
  } else {
    setPasswordError('');
  }
}

function validateOtp(value, setOtpError) {
  if (value.length > 0 && value.length != 6) {
    setOtpError('OTP must be of 6 digits');
  } else {
    setOtpError(constants.NO_ERROR_MESSAGE);
  }
}

function validateInput(value, minLength, setError) {
  if (value.length < minLength) {
    setError('Invalid Input');
  } else {
    setError('');
  }
}
function randomId(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}
function calculateAngle(coordinates) {
  let startLat = coordinates[0]['latitude'];
  let startLng = coordinates[0]['longitude'];
  let endLat = coordinates[1]['latitude'];
  let endLng = coordinates[1]['longitude'];
  let dx = endLat - startLat;
  let dy = endLng - startLng;

  return (Math.atan2(dy, dx) * 180) / Math.PI;
}
const getCurrentDate = () => {
  var date = new Date().getDate();
  var month = new Date().getMonth() + 1;
  var year = new Date().getFullYear();

  // You can turn it in to your desired format
  return year.toString() + '-' + month.toString() + '-' + date.toString(); //format: yyyy-mm-dd;
};

function unixTimeToMmDdYyyy(unixTimeInSeconds) {
  
  const date = new Date(unixTimeInSeconds);
  return date.toLocaleDateString("en-US");
}

function calculateDaysToGo(futureDate, futuredateHumanReadable) {
  var date = new Date(futureDate);

  // To calculate the no. of days between two dates(future - today)
  var differenceInDays = Math.floor(
    (date.getTime() - new Date().getTime()) / (1000 * 3600 * 24),
  );

  //console.log('CALCULATE_DAYS_TO_GO_FUTURE_DATE: ', futureDate);
  ////console.log("CALCULATE_DAYS_TO_GO_TODAY: ", getCurrentDate())
  //console.log('CALCULATE_DAYS_TO_GO: ', differenceInDays);
  
  
  /* if (differenceInDays <= 0) {
    return 'Today';
  } else if (differenceInDays == 30) {
    return 'In a month';
  } else if (differenceInDays > 30) {
    return futuredateHumanReadable;
  } else return differenceInDays + ' Days'; */
  return differenceInDays;
}

async function storeStringData(key, value) {
  try {
    //console.log('STORE_STRING_DATA: ', key+":"+value);
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    // saving error
    //console.log('STORE_STRING_DATA_ERROR: ', e);
  }
}
async function storeObjectData(key, value) {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
    //console.log('STORE_OBJECT_DATA: ', key+":"+jsonValue);
  } catch (e) {
    // saving error
    //console.log('STORE_OBJECT_DATA_ERROR: ', e);
  }
}
async function getStringData(key) {
  try {
    const value = await AsyncStorage.getItem(key);
    //console.log('GET_STRING_DATA: ', key+":"+value);
    if (value !== null) {
      // value previously stored
      return value;
    }
  } catch (e) {
    // error reading value
    //console.log('GET_STRING_DATA_ERROR: ', e);
  }
}
async function removeStringData(key) {
  try {
    const value = await AsyncStorage.removeItem(key);
    if (value !== null) {
      // value previously stored
      return value;
    }
  } catch (e) {
    // error removing value
    //console.log('REMOVE_STRING_DATA_ERROR: ', e);
  }
}
async function getObjectData(key) {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
}

async function storeBundles(value) {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem("BUNDLES", jsonValue);
  } catch (e) {
    // saving error
    //console.log('STORE_OBJECT_DATA_ERROR: ', e);
  }
}

async function getBundles( setBundles) {
  try {
    const jsonValue = await AsyncStorage.getItem("BUNDLES");
    //console.log("SAVED_BUNDLES:",jsonValue != null ? JSON.parse(jsonValue) : null);
    setBundles(jsonValue != null ? JSON.parse(jsonValue) : null);
  } catch (e) {
    // error reading value
  }
}

async function getTotalCoupons() {
  try {
    const coupons = await AsyncStorage.getItem(
      constants.ASYNC_STORAGE_DATA.COUPONS,
    );
    //console.log('COUPONS_NUMBER: ', JSON.parse(coupons).length);
    const coupons_others = await AsyncStorage.getItem(
      constants.ASYNC_STORAGE_DATA.COUPONS_OTHERS,
    );
    //console.log('COUPONS_OTHERS_NUMBER: ', JSON.parse(coupons_others).length);
    //return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
}
function getTotalCouponsValue(couponArray) {
  let total = 0.0;
  couponArray.forEach(coupon => {
    total = total + parseFloat(coupon.dollar_amount);
  });
  total = trimDecimals(total, 2);
  return total;
  ////console.log('COUPONS_VALUE: ', total);
}

function getMmDd(date) {
  var match = date.split('-')
  let dateMmDd = match[1]+"/"+match[2]+"/"+match[0];
  
  return dateMmDd;
  ////console.log('COUPONS_VALUE: ', total);
}


async function requestUserPermission() {
  const authStatus = await messaging().requestPermission();
  const enabled =
    authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
    authStatus === messaging.AuthorizationStatus.PROVISIONAL;

  if (enabled) {
    //console.log('Authorization status:', authStatus);
    getFcmToken();
  }
}

const getFcmToken = async() =>{
  try {
      const fcmToken = await messaging().getToken();
      //console.log("FCM Token generated ",fcmToken);
  } catch (error) {
      //console.log("Error in FCM Token ", error);
      //alert(error?.message);
  }

}

const validateUrl = url => {
  const re = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
  return re.test(url);
};

//Device informations
const getDeviceInfo = async () => {
  //DeviceID
  let deviceId =  '';
  await DeviceInfo.getUniqueId().then((uniqueId) => {
    deviceId = uniqueId
    });
  //Brand
  let brand = DeviceInfo.getBrand();
  //Device
  let DeviceName = '';
  await DeviceInfo.getDeviceName().then(deviceName => {
    DeviceName=deviceName
  });
  //SystemName
  let systemName = DeviceInfo.getSystemName();
  //SystemVersion
  let systemVersion = DeviceInfo.getSystemVersion();
  //App version
  let version = DeviceInfo.getVersion();
  let info = {
    device_brand: brand,
    device_os: systemName,
    device_os_version: systemVersion,
    device_model:DeviceName,
    device_unique_id: deviceId,
    device_app_version: version,
  };
  return info
};

const trimDecimals = (n, fixed) => {
  let value = `${n}`.match(new RegExp(`^-?\\d+(?:\.\\d{0,${fixed}})?`))[0];
  let decimalCount = 0;
  if (value.includes(".")) {
     decimalCount = value.split('.')[1].length;
     if (decimalCount == 1) {
      value+="0"
     }
  } else {
    value+=".00";
  }
  //console.log('trimTwoDecimal', value);
  return value
}

const formatLink = (link, fixed) => {
  //'0' = '.'
  //'1' = '/'
  //'-22-' = separator between proider link and display name
  //'3' = '&'
  let data = link.split('-22-')[0];
  data = data.replace('https://www.download.gitsy.app/','https://');
  data = data.replace(new RegExp('0', 'g'),'.');
  data = data.replace(new RegExp('1', 'g'),'/');
  data = data.replace(new RegExp('3', 'g'),'&');
  //console.log('formattedLink', data);
  return data
}
const getProviderName = (link, fixed) => {
  //'0' = '.'
  //'1' = '/'
  //'-22-' = separator between proider link and display name
  //'3' = '&'
  let data = link.split('-22-')[1];
  data = data.replace(new RegExp('0', 'g'),'.');
  data = data.replace(new RegExp('1', 'g'),'/');
  data = data.replace(new RegExp('3', 'g'),'&');
  //console.log('providerName', data);
  return data.toUpperCase();
}

const utils = {
  isValidEmail,
  validateEmail,
  isValidPhoneNumber,
  validatePhoneNumber,
  validatePassword,
  validateOtp,
  validateInput,
  randomId,
  calculateAngle,
  calculateDaysToGo,
  storeStringData,
  storeObjectData,
  getStringData,
  getObjectData,
  removeStringData,
  getTotalCoupons,
  getCurrentDate,
  getTotalCouponsValue,
  storeBundles,
  getBundles,
  requestUserPermission,
  getMmDd,
  unixTimeToMmDdYyyy,
  validateUrl,
  getDeviceInfo,
  trimDecimals,
  formatLink,
  getProviderName
};

export default utils;
