const back = require('../assets/icons/back.png');
const bar = require('../assets/icons/bar.png');
const coupon = require('../assets/icons/coupon.png');
const cross = require('../assets/icons/cross.png');
const cancel = require('../assets/icons/cancel.png');
const check_off = require('../assets/icons/check_off.png');
const check_on = require('../assets/icons/check_on.png');
const check_circle = require('../assets/icons/check_circle.png');
const delete_icon = require('../assets/icons/delete.png');
const discount = require('../assets/icons/discount.png');
const dollar = require('../assets/icons/dollar.png');
const dotted_line = require('../assets/icons/dotted_line.png');
const menu = require('../assets/icons/menu.png');
const menu1 = require('../assets/icons/menu1.png');
const minus = require('../assets/icons/minus.png');
const search = require('../assets/icons/search.png');
const filter = require('../assets/icons/filter.png');
const down_arrow = require('../assets/icons/down_arrow.png');
const favourite = require('../assets/icons/favourite.png');
const fb = require('../assets/icons/fb.png');
const clock = require('../assets/icons/clock.png');
const correct = require('../assets/icons/correct.png');
const map = require('../assets/icons/map.png');
const home = require('../assets/icons/home.png');
const location = require('../assets/icons/location.png');
const location1 = require('../assets/icons/location1.png');
const logout = require('../assets/icons/logout.png');
const notification = require('../assets/icons/notification.png');
const password = require('../assets/icons/password.png');
const plus = require('../assets/icons/plus.png');
const privacy = require('../assets/icons/privacy.png');
const setting = require('../assets/icons/setting.png');
const star = require('../assets/icons/star.png');
const term = require('../assets/icons/term.png');
const update = require('../assets/icons/update.png');
const wallet = require('../assets/icons/wallet.png');
const help = require('../assets/icons/help.png');
const globe = require('../assets/icons/globe.png');
const navigator = require('../assets/icons/navigator.png');
const navigator1 = require('../assets/icons/navigator1.png');
const my_coupons = require('../assets/icons/my_coupons.png');
const shopping_passes = require('../assets/icons/shopping_passes.png');
const my_profile = require('../assets/icons/my_profile.png');
const tcb_icon = require('../assets/icons/tcb_icon.png');
const bndl_help = require('../assets/icons/bndl_help.png');
const bndl_logout = require('../assets/icons/bndl_logout.png');
const bndl_myreceipts = require('../assets/icons/bndl_myreceipts.png');
const bndl_privacy = require('../assets/icons/bndl_privacy.png');
const bndl_terms = require('../assets/icons/bndl_terms.png');
const bndl_createbndl = require('../assets/icons/bndl_createbndl.png');
const bndl_delete = require('../assets/icons/bndl_delete.png');
const redeemed = require('../assets/icons/redeemed.png');
const not_redeemed = require('../assets/icons/not_redeemed.png');
const new_coupon = require('../assets/icons/new.png');
const expired = require('../assets/icons/expired.png');
const circle_plus = require('../assets/icons/circle_plus.png');
const arrow_right = require('../assets/icons/arrow_right.png');
const info = require('../assets/icons/info.png');
const barcode = require('../assets/icons/barcode.png');
const qr_code = require('../assets/icons/qr_code.png');
const edit = require('../assets/icons/edit.png');
const chatbot = require('../assets/icons/chatbot.png');

export default {
  back,
  bar,
  coupon,
  cross,
  cancel,
  check_off,
  check_on,
  check_circle,
  delete_icon,
  discount,
  dollar,
  dotted_line,
  menu,
  menu1,
  minus,
  search,
  filter,
  down_arrow,
  favourite,
  fb,
  clock,
  correct,
  map,
  home,
  location,
  location1,
  logout,
  notification,
  password,
  plus,
  privacy,
  setting,
  star,
  term,
  update,
  wallet,
  help,
  globe,
  navigator,
  navigator1,
  my_coupons,
  shopping_passes,
  my_profile,
  tcb_icon,
  bndl_help,
  bndl_logout,
  bndl_myreceipts,
  bndl_terms,
  bndl_privacy,
  bndl_createbndl,
  bndl_delete,
  redeemed,
  not_redeemed,
  expired,
  new_coupon,
  circle_plus,
  arrow_right,
  info,
  barcode,
  qr_code,
  edit,
  chatbot,
};
